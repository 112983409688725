import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { View, Text, ScrollView, TouchableOpacity, Linking, ActivityIndicator, Animated, Modal, Platform, Easing } from 'react-native';
import { Ionicons, MaterialIcons } from '@expo/vector-icons'; // Importation de MaterialIcons
import { auth, db } from '../firebase';
import { collection, query, where, getDocs, orderBy, limit, onSnapshot, doc } from 'firebase/firestore';
import { NavigationContext, useFocusEffect } from '@react-navigation/native';
import styles from '../screenStyles/HomeStyles';
import globalStyles, { colors } from '../screenStyles/GlobalStyles';

const openInstagram = () => {
  Linking.openURL('https://www.instagram.com/lmbarber72/');
};

const formatDate = (date) => {
  if (!date) return '';
  return date.toLocaleDateString('fr-FR', {
    weekday: 'long',
    day: 'numeric',
    month: 'long'
  });
};

const AppointmentCard = ({ appointment, onPress }) => {
  return (
    <TouchableOpacity style={styles.appointmentCard}>
      <Text style={styles.appointmentTitle}>Prochain rendez-vous</Text>
      <View style={styles.cardContainer}>
        <View style={styles.appointmentInfo}>
          <Text style={styles.appointmentDate}>
            {formatDate(appointment.dateTime)}
          </Text>
          <Text style={styles.appointmentTime}>
            Heure : {appointment.time}
          </Text>
          <View style={styles.statusAndButtonContainer}>
            <View style={[styles.statusBadge, { backgroundColor: colors.primary }]}>
              <Text style={styles.statusText}>Planifié</Text>
            </View>
            <TouchableOpacity
              style={styles.viewAppointmentButton}
              onPress={onPress}
            >
              <Text style={styles.viewAppointmentText}>Voir les détails</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const UserDashboard = ({ user }) => {
  const navigation = useContext(NavigationContext);
  const [nextAppointment, setNextAppointment] = useState(null);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [isVIP, setIsVIP] = useState(false);
  const [userData, setUserData] = useState(null); // Ajout de l'état userData
  const [loading, setLoading] = useState(true);
  const refreshIntervalRef = useRef(null);
  const fadeAnim = useRef(new Animated.Value(1)).current; // Démarrer à 1 pour éviter le fade du contenu
  const vipCardAnim = useRef(new Animated.Value(0)).current;
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const instagramButtonAnim = useRef(new Animated.Value(0)).current; // Initialiser à 0 pour qu'il soit visible dès le début
  const [hasMounted, setHasMounted] = useState(false);
  const appointmentCardAnim = useRef(new Animated.Value(0)).current;
  const previousVIPStatus = useRef(null);
  const [shouldShowVIPCard, setShouldShowVIPCard] = useState(false);
  const pointsLoadingAnim = useRef(new Animated.Value(0)).current;
  const [showPoints, setShowPoints] = useState(false);

  useEffect(() => {
    let unsubscribeUser;
    let unsubscribeAppointments;

    const setupSubscriptions = async () => {
      try {
        const usersQuery = query(collection(db, 'users'), where('email', '==', user.email));
        const userSnapshot = await getDocs(usersQuery);
        
        if (!userSnapshot.empty) {
          const userId = userSnapshot.docs[0].id;
          
          unsubscribeUser = onSnapshot(doc(db, 'users', userId), (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              setUserData(userData); // Stockage des données utilisateur
              const newPoints = parseInt(userData.loyaltyPoints) || 0;
              setLoyaltyPoints(newPoints);
              
              // Animation simple des points
              pointsLoadingAnim.setValue(0);
              Animated.timing(pointsLoadingAnim, {
                toValue: 1,
                duration: 300,
                useNativeDriver: false,
                easing: Easing.out(Easing.ease)
              }).start();

              const newVIPStatus = Boolean(userData.isVIP);
              if (previousVIPStatus.current !== newVIPStatus) {
                previousVIPStatus.current = newVIPStatus;
                setIsVIP(newVIPStatus);
                if (!isFirstLoad) {  // Ne pas animer si c'est le premier chargement
                  vipCardAnim.setValue(0);
                  setShouldShowVIPCard(true);
                  Animated.spring(vipCardAnim, {
                    toValue: 1,
                    friction: 8,
                    tension: 40,
                    useNativeDriver: false
                  }).start();
                }
              }
            }
          });

          const appointmentsQuery = query(
            collection(db, 'appointments'),
            where('userInfo.email', '==', user.email),
            where('status', '==', 'scheduled'),
            orderBy('dateTime'),
            limit(1)
          );

          unsubscribeAppointments = onSnapshot(appointmentsQuery, (snapshot) => {
            if (!snapshot.empty) {
              const appointmentData = snapshot.docs[0].data();
              setNextAppointment({
                ...appointmentData,
                dateTime: appointmentData.dateTime.toDate(),
                id: snapshot.docs[0].id
              });
            } else {
              setNextAppointment(null);
            }
            setLoading(false);
          });
        }
      } catch (error) {
        console.error("Erreur dans l'initialisation des souscriptions:", error);
        setLoading(false);
      }
    };

    setupSubscriptions();

    return () => {
      previousVIPStatus.current = null;
      setShouldShowVIPCard(false);
      if (unsubscribeUser) unsubscribeUser();
      if (unsubscribeAppointments) unsubscribeAppointments();
    };
  }, [user.email]);

  useEffect(() => {
    if (!loading) {
      appointmentCardAnim.setValue(0);
      Animated.sequence([
        Animated.delay(200),
        Animated.spring(appointmentCardAnim, {
          toValue: 1,
          friction: 8,
          tension: 40,
          useNativeDriver: false
        })
      ]).start();
    }
  }, [nextAppointment, loading]);

  useFocusEffect(
    useCallback(() => {
      if (!hasMounted) {
        setHasMounted(true);
        setTimeout(() => {
          vipCardAnim.setValue(0);
          setShouldShowVIPCard(true);
          Animated.timing(vipCardAnim, {
            toValue: 1,
            duration: 400,
            useNativeDriver: false
          }).start(() => {
            setIsFirstLoad(false);
          });
        }, 300);
      }
      
      return () => {
        setHasMounted(false);
        setShouldShowVIPCard(false);
      };
    }, [])
  );

  const handleViewAppointments = () => {
    if (nextAppointment) {
      navigation?.navigate('Réservation', {
        showAppointmentModal: true,
        appointmentToShow: nextAppointment,
        scrollToAppointments: true
      });
    }
  };

  const handleViewRewards = () => {
    navigation?.navigate('Fidélité', { activeTab: 'rewards' });
  };

  const handleScrollToVIP = () => {
    navigation?.navigate('Fidélité', { 
      activeTab: 'rewards',
      scrollToVIP: true
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollView 
        style={[
          styles.dashboardContainer,
          Platform.OS === 'web' && {
            marginTop: 'calc(env(safe-area-inset-top, 47px) + 30px)', // Réduit de 60px à 30px
            marginBottom: 'calc(env(safe-area-inset-bottom, 34px) + 85px)',
          }
        ]}
      >
        <View style={styles.quickActionsContainer}>
          <TouchableOpacity 
            style={styles.actionButton}
            onPress={() => navigation?.navigate('Réservation')}
          >
            <View style={styles.actionIconContainer}>
              <Ionicons 
                name="calendar-outline" 
                size={24} 
                color="#FFFFFF" 
                style={styles.actionIcon}
              />
            </View>
            <Text style={styles.actionText}>Réserver</Text>
          </TouchableOpacity>

          <TouchableOpacity 
            style={styles.actionButton}
            onPress={handleViewRewards}
          >
            <View style={styles.actionIconContainer}>
              <Ionicons 
                name="gift-outline" 
                size={28} 
                color="#FFFFFF" 
                style={styles.actionIcon}
              />
            </View>
            <Text style={styles.actionText}>Récompenses</Text>
          </TouchableOpacity>

          <TouchableOpacity 
            style={styles.actionButton}
            onPress={() => navigation?.navigate('Compte')}
          >
            <View style={styles.actionIconContainer}>
              <Ionicons 
                name="person-outline" 
                size={28} 
                color="#FFFFFF" 
                style={styles.actionIcon}
              />
            </View>
            <Text style={styles.actionText}>Profil</Text>
          </TouchableOpacity>
        </View>

        <View style={styles.pointsCard}>
          <Text style={styles.pointsLabel}>Mes Points de fidélité</Text>
          <View style={styles.pointsRow}>
            <Animated.View style={{
              opacity: pointsLoadingAnim,
              transform: [{
                translateY: pointsLoadingAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [10, 0],
                })
              }]
            }}>
              <Text style={styles.pointsValue}>✨ {loyaltyPoints} points ✨</Text>
            </Animated.View>
            <TouchableOpacity 
              style={styles.viewRewardsButton}
              onPress={handleViewRewards}
            >
              <Text style={styles.viewRewardsText}>Voir les récompenses</Text>
            </TouchableOpacity>
          </View>
        </View>

        <Animated.View style={{
          opacity: appointmentCardAnim,
          transform: [{
            scale: appointmentCardAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [0.9, 1]
            })
          }]
        }}>
          {nextAppointment ? (
            <View style={styles.appointmentCard}>
              <Text style={[styles.appointmentTitle, { textAlign: 'center' }]}>
                Prochain rendez-vous
              </Text>
              <View style={styles.cardContainer}>
                {loading ? (
                  <View style={styles.loadingContainer}>
                    <ActivityIndicator size="small" color={colors.primary} />
                    <Text style={styles.loadingText}>Chargement du rendez-vous...</Text>
                  </View>
                ) : (
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <View style={styles.appointmentInfo}>
                      <Text style={styles.appointmentDate}>
                        {formatDate(nextAppointment.dateTime)}
                      </Text>
                      <Text style={styles.appointmentTime}>
                        Heure : {nextAppointment.time}
                      </Text>
                      <View style={[styles.statusBadge, { backgroundColor: colors.primary }]}>
                        <Text style={styles.statusText}>Planifié</Text>
                      </View>
                    </View>
                    <TouchableOpacity
                      style={styles.viewAppointmentButton}
                      onPress={handleViewAppointments}
                    >
                      <Text style={styles.viewAppointmentText}>Voir les détails</Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          ) : (
            <View style={styles.noAppointmentCard}>
              <View style={styles.noAppointmentContent}>
                <Ionicons name="calendar" size={32} color={colors.primary} />
                <Text style={styles.noAppointmentText}>
                  Aucun rendez-vous prévu
                </Text>
                <TouchableOpacity
                  style={styles.bookButton}
                  onPress={() => navigation?.navigate('Réservation')}
                >
                  <Text style={styles.bookButtonText}>Réserver maintenant</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </Animated.View>

        {/* VIP Card - Déplacée avant le bouton Instagram */}
        {shouldShowVIPCard && (
          <Animated.View style={{
            opacity: vipCardAnim,
            transform: [{
              translateY: vipCardAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [50, 0]
              })
            }],
          }}>
            {isVIP && userData ? ( // Ajout de la vérification de userData
              <View style={styles.vipCard}>
                <View style={styles.vipContent}>
                  <Text style={styles.vipTitle}>
                    👑 Membre VIP 👑
                  </Text>
                  <Text style={styles.vipDescription}>
                    Profite de tes avantages exclusifs VIP :{'\n'}
                    • Coupes gratuites{'\n'}
                    • Points doublés{'\n'}
                    • Accès prioritaire
                  </Text>
                  {userData?.vipEndDate && (
                    <>
                      <Text style={styles.vipExpiryText}>
                        Prend fin le : {formatDate(new Date(userData.vipEndDate))}
                      </Text>
                      <TouchableOpacity 
                        style={styles.vipRenewButton}
                        onPress={() => Linking.openURL('https://pay.sumup.com/b2c/QFG16TK0')}
                      >
                        <Ionicons name="refresh" size={18} color="#FFFFFF" />
                        <Text style={styles.vipRenewButtonText}>Prolonger VIP d'1 mois</Text>
                      </TouchableOpacity>
                    </>
                  )}
                </View>
              </View>
            ) : (
              <View style={styles.promoCard}>
                <View style={styles.promoContent}>
                  <Text style={styles.promoTitle}>
                    Deviens VIP 👑
                  </Text>
                  <Text style={styles.promoDescription}>
                    Profite d'avantages exclusifs :{'\n'}
                    • Coupes gratuites{'\n'}
                    • Points doublés{'\n'}
                    • Accès prioritaire{'\n\n'}
                    Pour seulement 35€/mois
                  </Text>
                  <TouchableOpacity 
                    style={styles.promoButton}
                    onPress={handleScrollToVIP}
                  >
                    <Text style={styles.promoButtonText}>Découvrir l'offre VIP</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </Animated.View>
        )}
        
        {/* Remplacer le bouton Instagram fixe par la carte Instagram intégrée */}
        <TouchableOpacity 
          style={styles.instagramCard}
          onPress={openInstagram}
        >
          <View style={styles.instagramGradient}>
            <View style={styles.instagramContent}>
              <View style={styles.instagramTextContainer}>
                <Text style={styles.instagramTitle}>Suis mes réalisations !</Text>
                <Text style={styles.instagramSubtitle}>@LmBarber72</Text>
              </View>
              <View style={styles.instagramIconContainer}>
                <Ionicons name="logo-instagram" size={24} color="white" />
              </View>
            </View>
          </View>
        </TouchableOpacity>

        {/* Remplacer la ligne existante du bottomSpacer dans UserDashboard */}
        <View style={[globalStyles.bottomSpacer, { marginTop: 0, height: 5 }]} />
      </ScrollView>
    </View>
  );
};

const GuestView = () => {
  const navigation = useContext(NavigationContext);
  const [showInstallModal, setShowInstallModal] = useState(false);
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                   window.navigator.standalone;
      setIsPWAInstalled(isPWA);
    }
  }, []);

  const isSafari = () => {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    return iOS && webkit;
  };

  const InstallModal = () => (
    <Modal
      animationType="fade"
      transparent={true}
      visible={showInstallModal}
      onRequestClose={() => setShowInstallModal(false)}
    >
      <TouchableOpacity 
        style={styles.modalOverlay}
        activeOpacity={1}
        onPressOut={() => setShowInstallModal(false)}
      >
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Installation de l'application</Text>
          
          {isSafari() ? (
            <View style={styles.instructionContainer}>
              <Text style={styles.platformLabel}>Sur iPhone :</Text>
              <View style={styles.instructionStep}>
                <Text style={styles.instructionText}>
                  1. Dans Safari, appuie sur le bouton Partager (l'icône avec une flèche)
                </Text>
                <Ionicons name="share-outline" size={24} color={colors.primary} />
              </View>
              <View style={styles.instructionStep}>
                <Text style={styles.instructionText}>
                  2. Fais défiler et choisis "Sur l'écran d'accueil"
                </Text>
                <Ionicons name="add-circle-outline" size={24} color={colors.primary} />
              </View>
              <View style={styles.instructionStep}>
                <Text style={styles.instructionText}>
                  3. Appuie sur "Ajouter"
                </Text>
                <Ionicons name="checkmark-circle-outline" size={24} color={colors.primary} />
              </View>
              <Text style={styles.instructionNote}>
                Important : Tu dois faire l'installation depuis Safari. L'app apparaîtra ensuite sur ton écran d'accueil.
              </Text>
            </View>
          ) : (
            <View style={styles.instructionContainer}>
              <Text style={styles.platformLabel}>Sur Android :</Text>
              <View style={styles.instructionStep}>
                <Text style={styles.instructionText}>
                  1. Dans Chrome, appuie sur les trois points en haut à droite
                </Text>
                <Ionicons name="ellipsis-vertical" size={24} color={colors.primary} />
              </View>
              <View style={styles.instructionStep}>
                <Text style={styles.instructionText}>
                  2. Sélectionne "Ajouter à l'écran d'accueil"
                </Text>
                <MaterialIcons name="add-to-home-screen" size={24} color={colors.primary} />
              </View>
              <View style={styles.instructionStep}>
                <Text style={styles.instructionText}>
                  3. Appuie sur "Installer"
                </Text>
                <Ionicons name="checkmark-circle-outline" size={24} color={colors.primary} />
              </View>
              <Text style={styles.instructionNote}>
                Note : Tu dois faire l'installation depuis Chrome
              </Text>
            </View>
          )}
          
          <TouchableOpacity 
            style={styles.closeModalButton}
            onPress={() => setShowInstallModal(false)}
          >
            <Text style={styles.closeModalButtonText}>Compris !</Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </Modal>
  );

  const features = [
    {
      icon: "calendar-outline",
      title: "Réservation Simple",
      description: "Réserve ton rendez-vous en quelques clics",
      color: colors.primary
    },
    {
      icon: "gift-outline",
      title: "Programme Fidélité",
      description: "Gagne des points à chaque visite",
      color: colors.accent
    },
    {
      icon: "star-outline",
      title: "Status VIP",
      description: "Coupes gratuites et accès prioritaire",
      color: '#7B1FA2'
    },
    {
      icon: "timer-outline",
      title: "Rappels & Suivi",
      description: "Ne manque plus aucun rendez-vous",
      color: colors.secondary
    }
  ];

  const leftColumn = features.filter((_, index) => index % 2 === 0);
  const rightColumn = features.filter((_, index) => index % 2 === 1);

  return (
    <ScrollView 
      style={styles.guestScroll}
      showsVerticalScrollIndicator={false}
    >
      <View style={styles.guestContent}>
        <View style={styles.heroBanner}>
          <View style={styles.heroIconContainer}>
            <Ionicons name="cut" size={40} color="white" />
          </View>
          <Text style={styles.heroTitle}>
          💈 Bienvenue sur LmPlanner 💈
          </Text>
          <Text style={styles.heroSubtitle}>
            Prends-ton rendez-vous en ligne dès maintenant
          </Text>
          <View style={styles.heroButtonsContainer}>
            <TouchableOpacity 
              style={styles.signUpButton}
              onPress={() => navigation?.navigate('Compte', { mode: 'register' })}
            >
              <Text style={styles.signUpButtonText}>
                S'inscrire Gratuitement
              </Text>
            </TouchableOpacity>
            <View style={styles.loginContainer}>
              <Text style={styles.loginLabel}>
                Déjà membre ?
              </Text>
              <TouchableOpacity 
                style={styles.loginButtonHero}
                onPress={() => navigation?.navigate('Compte', { mode: 'login' })}
              >
                <Text style={styles.loginButtonHeroText}>
                  Se connecter
                </Text>
              </TouchableOpacity>
            </View>
            
            {!isPWAInstalled && Platform.OS === 'web' && (
              <TouchableOpacity 
                style={styles.installButton}
                onPress={() => setShowInstallModal(true)}
              >
                <Ionicons name="download-outline" size={14} color="rgba(255, 255, 255, 0.9)" />
                <Text style={styles.installButtonText}>
                  Installer l'app
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>

        <Text style={styles.sectionTitle}>Pourquoi choisir LmPlanner ?</Text>

        <View style={styles.featuresContainer}>
          <View style={styles.featureColumn}>
            {leftColumn.map((feature, index) => (
              <View 
                key={index * 2} 
                style={[
                  styles.featureCard,
                  { borderColor: feature.color, width: '100%' }
                ]}
              >
                <View style={[styles.featureIconContainer, { backgroundColor: feature.color }]}>
                  <Ionicons name={feature.icon} size={24} color="white" />
                </View>
                <Text style={[styles.featureTitle, { color: feature.color }]}>
                  {feature.title}
                </Text>
                <Text style={styles.featureDescription}>
                  {feature.description}
                </Text>
              </View>
            ))}
          </View>
          
          <View style={styles.featureColumn}>
            {rightColumn.map((feature, index) => (
              <View 
                key={index * 2 + 1} 
                style={[
                  styles.featureCard,
                  { borderColor: feature.color, width: '100%' }
                ]}
              >
                <View style={[styles.featureIconContainer, { backgroundColor: feature.color }]}>
                  <Ionicons name={feature.icon} size={24} color="white" />
                </View>
                <Text style={[styles.featureTitle, { color: feature.color }]}>
                  {feature.title}
                </Text>
                <Text style={styles.featureDescription}>
                  {feature.description}
                </Text>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.socialProof}>
          <Text style={styles.socialProofTitle}>
            Rejoins-moi directement sur Instagram !
          </Text>
          <TouchableOpacity
            style={styles.instagramButton}
            onPress={openInstagram}
          >
            <Ionicons name="logo-instagram" size={20} color="white" />
            <Text style={styles.instagramButtonText}>
              Découvre mes réalisations
            </Text>
          </TouchableOpacity>
        </View>
        {/* Remplacer la ligne existante du bottomSpacer dans GuestView */}
        <View style={[globalStyles.bottomSpacer, { marginTop: 0, height: 5 }]} />
      </View>
      <InstallModal />
    </ScrollView>
  );
};

const HomeScreen = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsInitializing(false);
    });
    return () => unsubscribe();
  }, []);

  // Montrer un écran de chargement pendant l'initialisation
  if (isInitializing) {
    return (
      <View style={[styles.container, { justifyContent: 'center', alignItems: 'center' }]}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {currentUser ? (
        <UserDashboard user={currentUser} />
      ) : (
        <GuestView />
      )}
    </View>
  );
};

export default HomeScreen;