import { StyleSheet, Platform } from 'react-native';
import { isPWA, colors } from '../screenStyles/GlobalStyles';

export default StyleSheet.create({
  container: {
    position: 'absolute',
    top: Platform.OS === 'web' && isPWA() ? 
      'calc(70px + env(safe-area-inset-top, 47px))' : 70,
    left: 0,
    right: 0,
    marginHorizontal: 'auto',
    width: '90%',
    maxWidth: 400,
    minHeight: 60,
    borderRadius: 8,
    borderWidth: 1,
    zIndex: 1100,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  icon: {
    marginRight: 12,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  message: {
    fontSize: 14,
    color: colors.text,
  },
  closeButton: {
    padding: 8,
    marginLeft: 8,
  },
  closeButtonPressed: {
    opacity: 0.7,
  },
});
