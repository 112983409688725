// AppointmentHistory.js
import React, { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle, useMemo } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Modal, ActivityIndicator, Animated, Dimensions, Pressable, Linking, ScrollView, Image } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { colors } from '../screenStyles/GlobalStyles';
import styles from '../componentStyles/AppointmentHistoryStyles';
import { db } from '../firebase';
import { doc, updateDoc, getDoc, runTransaction, collection, query, where, getDocs, Timestamp } from 'firebase/firestore';


const API_URL = 'https://13-39-76-140.nip.io';
const openInstagram = () => {
  Linking.openURL('https://www.instagram.com/lmbarber72/');
};

const AppointmentCard = ({ appointment, onCancelPress, onPayPress, onPress }) => {
  const [showMediaModal, setShowMediaModal] = useState(false);
  const now = new Date();
  const isUpcoming = appointment.dateTime > now && appointment.status === 'scheduled';
  const isCompleted = appointment.status === 'completed';

  const formatDate = (date) => {
    if (!date) return '';
    return date.toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const formatTime = (time) => {
    return time;
  };

  if (!appointment.dateTime || !(appointment.dateTime instanceof Date)) {
    console.error('Invalid date for appointment:', appointment);
    return null;
  }

  const renderPaymentStatus = () => {
    if (!isUpcoming || appointment.finalPrice === 0) return null;
    
    // Safely check if payment property exists and has a status
    if (!appointment.payment) {
      return (
        <TouchableOpacity
          style={[styles.actionButton, styles.payButton]}
          onPress={() => onPayPress(appointment)}
        >
          <Text style={styles.payButtonText}>Payer en avance</Text>
        </TouchableOpacity>
      );
    }
    
    // Now we can safely check the payment status
    switch(appointment.payment.status) {
      case 'pending':
        return (
          <View style={styles.pendingBadge}>
            <ActivityIndicator size="small" color={colors.primary} />
            <Text style={styles.pendingText}>Vérification en cours</Text>
          </View>
        );
      
      case 'paid':
        return (
          <View style={styles.paidBadge}>
            <Ionicons name="checkmark-circle" size={20} color={colors.success} />
            <Text style={styles.paidText}>Payé</Text>
          </View>
        );
      
      default:
        return (
          <TouchableOpacity
            style={[styles.actionButton, styles.payButton]}
            onPress={() => onPayPress(appointment)}
          >
            <Text style={styles.payButtonText}>Payer en avance</Text>
          </TouchableOpacity>
        );
    }
  };

  const openInstagram = () => {
    Linking.openURL('https://www.instagram.com/7lm.barber/');
  };

  const MediaModal = ({ visible, appointment, onClose }) => {
    const [currentMedia, setCurrentMedia] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
  
    useEffect(() => {
      if (visible && appointment) {
        if (appointment.id) {
          const fetchMediaUrls = async () => {
            try {
              const appointmentRef = doc(db, 'appointments', appointment.id);
              const appointmentDoc = await getDoc(appointmentRef);
              if (appointmentDoc.exists()) {
                const data = appointmentDoc.data();
                setCurrentMedia(data.mediaUrls || []);
              }
            } catch (error) {
              console.error('Error fetching media URLs:', error);
            }
          };
          fetchMediaUrls();
        }
      }
    }, [visible, appointment]);
  
    const renderMediaItem = (mediaUrl, index) => {
      const isVideo = mediaUrl.includes('video') || mediaUrl.endsWith('.mp4');
      const videoThumbnailUrl = isVideo
        ? mediaUrl.replace('/upload/', '/upload/so_1/').replace('.mp4', '.jpg')
        : null;
  
      return (
        <TouchableOpacity
          key={index}
          style={{
            position: 'relative',
            width: 100,
            height: 100,
            marginRight: 10,
            borderRadius: 10,
            backgroundColor: colors.background,
            borderWidth: 1,
            borderColor: colors.border,
            overflow: 'hidden',
          }}
          onPress={() => {
            if (isVideo) {
              Linking.openURL(mediaUrl);
            } else {
              setSelectedImage(mediaUrl);
            }
          }}
        >
          {isVideo ? (
            <>
              <Image
                source={{ uri: videoThumbnailUrl }}
                style={{
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onError={() => console.error(`Thumbnail not found for video: ${mediaUrl}`)}
              />
              <View style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: [{ translateX: -16 }, { translateY: -16 }],
                zIndex: 5,
              }}>
                <Ionicons name="play-circle" size={32} color="white" />
              </View>
            </>
          ) : (
            <Image
              source={{ uri: mediaUrl }}
              style={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              resizeMode="cover"
            />
          )}
        </TouchableOpacity>
      );
    };
  
    return (
      <>
        <Modal
          visible={visible}
          transparent={true}
          animationType="fade"
          onRequestClose={onClose}
        >
          <Pressable 
            style={{
              flex: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={onClose}
          >
            <Pressable 
              style={{
                backgroundColor: 'white',
                borderRadius: 16,
                width: '92%',
                maxWidth: 360,
                alignSelf: 'center',
                overflow: 'hidden',
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 4 },
                shadowOpacity: 0.15,
                shadowRadius: 12,
                elevation: 8,
                padding: 12,
              }}
              onPress={e => e.stopPropagation()}
            >
              <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 8,
                borderBottomWidth: 1,
                borderBottomColor: colors.primary,
                paddingBottom: 8,
                position: 'relative',
              }}>
                <Text style={{
                  fontSize: 18,
                  fontWeight: '600',
                  color: colors.primary,
                  textAlign: 'center',
                }}>Médias du rendez-vous</Text>
                <TouchableOpacity 
                  onPress={onClose}
                  style={{
                    position: 'absolute',
                    right: 0,
                    backgroundColor: colors.lightGrey,
                    borderRadius: 12,
                    padding: 2,
                  }}
                >
                  <Ionicons name="close" size={18} color={colors.primary} />
                </TouchableOpacity>
              </View>
  
              <View style={{ flex: 1 }}>
                {currentMedia.length > 0 ? (
                  <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    style={{
                      flexGrow: 0,
                      height: 120,
                      marginBottom: 20,
                    }}
                    contentContainerStyle={{
                      paddingHorizontal: 5,
                      paddingVertical: 5,
                    }}
                  >
                    {currentMedia.map((mediaUrl, index) => renderMediaItem(mediaUrl, index))}
                  </ScrollView>
                ) : (
                  <View style={{
                    backgroundColor: colors.background,
                    padding: 10,
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: colors.primary,
                    minHeight: 200,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    <Ionicons 
                      name="images-outline" 
                      size={48} 
                      color={colors.primary}
                      style={{ marginBottom: 12 }}
                    />
                    <Text style={{
                      fontSize: 14,
                      color: colors.text,
                      textAlign: 'center',
                      marginBottom: 4,
                    }}>Aucune photo disponible pour le moment</Text>
                    <Text style={{
                      fontSize: 12,
                      color: colors.textLight,
                      textAlign: 'center',
                    }}>Les photos seront disponibles après le rendez-vous</Text>
                  </View>
                )}
              </View>
  
              <TouchableOpacity
                style={{
                  backgroundColor: '#E1306C',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 10,
                  borderRadius: 8,
                  marginTop: 12,
                }}
                onPress={() => Linking.openURL('https://www.instagram.com/7lm.barber/')}
              >
                <Ionicons name="logo-instagram" size={18} color="white" />
                <Text style={{
                  color: 'white',
                  fontSize: 13,
                  fontWeight: '600',
                  marginLeft: 8,
                }}>Voir plus de photos sur Instagram</Text>
              </TouchableOpacity>
            </Pressable>
          </Pressable>
        </Modal>
  
        {selectedImage && (
          <Modal
            visible={!!selectedImage}
            transparent={true}
            animationType="fade"
            onRequestClose={() => setSelectedImage(null)}
          >
            <Pressable
              style={{
                flex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={() => setSelectedImage(null)}
            >
              <Image
                source={{ uri: selectedImage }}
                style={{
                  width: '90%',
                  height: '80%',
                  borderRadius: 8,
                }}
                resizeMode="contain"
              />
            </Pressable>
          </Modal>
        )}
      </>
    );
  };

  return (
    <TouchableOpacity onPress={() => onPress(appointment)}>
      <View style={styles.appointmentCard}>
        <View style={styles.cardContainer}>
          <View style={styles.appointmentInfo}>
            <Text style={styles.appointmentDate}>
              {formatDate(appointment.dateTime)}
            </Text>
            <Text style={styles.appointmentTime}>
              Heure : {formatTime(appointment.time)}
            </Text>
            <View style={[
              styles.statusBadge,
              { 
                backgroundColor: 
                  appointment.status === 'scheduled' ? colors.primary : 
                  appointment.status === 'cancelled' ? colors.error :
                  '#6c757d'
              }
            ]}>
              <Text style={styles.statusText}>
                {appointment.status === 'scheduled' ? 'Planifié' : 
                 appointment.status === 'cancelled' ? 'Annulé' : 
                 'Terminé'}
              </Text>
            </View>
          </View>

          <View style={styles.buttonContainer}>
            <View style={styles.topButtons}>
              {isUpcoming && (
                <TouchableOpacity
                  style={[
                    styles.actionButton, 
                    {
                      marginHorizontal: 8,
                      backgroundColor: 'white',
                      borderWidth: 2,
                      borderColor: '#007AFF',
                      padding: 8,
                      borderRadius: 8,
                    }
                  ]}
                  onPress={() => onPress(appointment)}
                >
                  <Ionicons 
                    name="information-circle" 
                    size={24} 
                    color="#007AFF"
                  />
                </TouchableOpacity>
              )}

              {isCompleted && (
                <TouchableOpacity
                  style={[
                    styles.actionButton,
                    {
                      marginHorizontal: 8,
                      backgroundColor: colors.primary,
                      padding: 8,
                      borderRadius: 8,
                    }
                  ]}
                  onPress={() => setShowMediaModal(true)}
                >
                  <Ionicons 
                    name="images-outline" 
                    size={24} 
                    color="white"
                  />
                </TouchableOpacity>
              )}

              {isUpcoming && onCancelPress && (
                <TouchableOpacity
                  style={[styles.actionButton, styles.cancelButton]}
                  onPress={() => onCancelPress(appointment)}
                >
                  <Ionicons 
                    name="close-outline" 
                    size={24} 
                    color="white"
                  />
                </TouchableOpacity>
              )}
            </View>
            
            {isUpcoming && appointment.status !== 'cancelled' && (
              <View style={styles.bottomButton}>
                {renderPaymentStatus()}
              </View>
            )}
          </View>
        </View>

        <MediaModal 
          visible={showMediaModal}
          appointment={appointment}
          onClose={() => setShowMediaModal(false)}
        />
        
      </View>
    </TouchableOpacity>
  );
};

const AnimatedAppointmentCard = ({ appointment, onCancelPress, onPayPress, onPress, index }) => {
  const translateY = useRef(new Animated.Value(50)).current;
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.parallel([
      Animated.timing(translateY, {
        toValue: 0,
        duration: 500,
        delay: index * 100,
        useNativeDriver: false,
      }),
      Animated.timing(opacity, {
        toValue: 1,
        duration: 500,
        delay: index * 100,
        useNativeDriver: false,
      }),
    ]).start();
  }, []);

  return (
    <Animated.View
      style={{
        transform: [{ translateY }],
        opacity,
      }}
    >
      <AppointmentCard
        appointment={appointment}
        onCancelPress={onCancelPress}
        onPayPress={onPayPress}
        onPress={onPress}
      />
    </Animated.View>
  );
};

const AppointmentHistory = React.forwardRef(({ appointments, onAppointmentUpdate, showNotification, userData }, ref) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const slideAnim = useRef(new Animated.Value(0)).current;
  const [containerWidth, setContainerWidth] = useState(0);

  const tabWidth = (containerWidth - 16) / 2;

  // Définir translateX ici, avant son utilisation
  const translateX = slideAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, tabWidth],
  });

  const openModal = useCallback((appointment) => {
    if (appointment) {
      // Calculer le prix final en vérifiant toutes les sources possibles
      let finalPrice = appointment.bookingDetails?.finalPrice;
      if (finalPrice === undefined || finalPrice === null) {
        finalPrice = appointment.finalPrice;
      }
      if (finalPrice === undefined || finalPrice === null) {
        finalPrice = appointment.service?.price;
      }

      const preparedAppointment = {
        ...appointment,
        dateTime: appointment.dateTime instanceof Date ? appointment.dateTime : new Date(appointment.dateTime),
        service: {
          ...appointment.service,
          price: appointment.service?.price || 0
        },
        finalPrice: finalPrice || 0
      };
      
      setSelectedAppointment(preparedAppointment);
      setShowInfoModal(true);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    openModal,
    setActiveTab: async (tab) => {
      await animateSlide(tab);
    }
  }));

  const onLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width);
  };

  const animateSlide = useCallback((tab) => {
    // Mettre à jour le tab immédiatement pour un meilleur ressenti
    setActiveTab(tab);
    
    return new Promise((resolve) => {
      Animated.spring(slideAnim, {
        toValue: tab === 'upcoming' ? 0 : 1,
        friction: 12, // Augmenter la friction pour une animation plus rapide
        tension: 80, // Augmenter la tension pour un mouvement plus direct
        useNativeDriver: false, // Utiliser le native driver pour de meilleures performances
      }).start(({ finished }) => {
        if (finished) {
          resolve();
        }
      });
    });
  }, []);

  const handleCancelPress = (appointment) => {
    setSelectedAppointment(appointment);
    setShowCancelModal(true);
  };

  const handleCancelConfirm = async () => {
    if (!selectedAppointment || !userData) {
      console.log('Missing data:', { selectedAppointment, userData });
      return;
    }
  
    setIsLoading(true);
    try {
      // Vérifier si des points ont été dépensés pour une réduction
      if (selectedAppointment.reward) {
        // Trouver l'utilisateur par email dans Firestore
        const usersRef = collection(db, 'users');
        const userQuery = query(usersRef, where('email', '==', userData.email));
        const userSnapshot = await getDocs(userQuery);
        
        if (userSnapshot.empty) {
          throw new Error('Utilisateur non trouvé');
        }
  
        const userDoc = userSnapshot.docs[0];
        const userRef = doc(db, 'users', userDoc.id);
  
        await runTransaction(db, async (transaction) => {
          const userSnapshot = await transaction.get(userRef);
          
          if (!userSnapshot.exists()) {
            throw new Error('Utilisateur non trouvé');
          }
  
          // Calculer le nouveau solde de points
          const currentPoints = parseInt(userSnapshot.data().loyaltyPoints) || 0;
          const pointsToRefund = selectedAppointment.reward.points;
          const newPoints = currentPoints + pointsToRefund;
  
          // Mettre à jour les points de l'utilisateur
          transaction.update(userRef, {
            loyaltyPoints: newPoints.toString()
          });
  
          // Ajouter une entrée dans l'historique des points
          const historyRef = doc(collection(db, 'pointsHistory'));
          // Nettoyer et valider les données du service
          const cleanService = selectedAppointment.service ? {
            name: selectedAppointment.service.name || 'Service non spécifié',
            price: selectedAppointment.service.price || 0,
            points: selectedAppointment.service.points || 0,
            id: selectedAppointment.service.id || 'unknown'
          } : null;
  
          // Nettoyer et valider les données de la récompense
          const cleanReward = selectedAppointment.reward ? {
            id: selectedAppointment.reward.id || 'unknown',
            name: selectedAppointment.reward.name || 'Récompense non spécifiée',
            points: selectedAppointment.reward.points || 0,
            discount: selectedAppointment.reward.discount || 0
          } : null;
  
          const historyEntry = {
            userId: userDoc.id,
            userEmail: userData.email,
            pointsChange: pointsToRefund,
            type: 'refund',
            description: `Remboursement points : Annulation RDV ${cleanReward?.name || 'Récompense'}`,
            timestamp: Timestamp.now(),
            finalPoints: newPoints,
            rewardId: cleanReward?.id,
            rewardName: cleanReward?.name,
            bookingDetails: {
              service: cleanService,
              reward: cleanReward
            }
          };
          
          transaction.set(historyRef, historyEntry);
        });
  
        console.log('Points refunded successfully');
      }
  
      // Préparer les données pour l'annulation du rendez-vous
      const customerName = `${selectedAppointment.userInfo?.firstName || ''} ${selectedAppointment.userInfo?.lastName || ''}`.trim() || "Un client";
      const formattedDateTime = selectedAppointment.dateTime.toISOString();
  
      // Appeler l'API pour annuler le rendez-vous
      const response = await fetch(`${API_URL}/api/slots/cancel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          appointmentTime: formattedDateTime,
          customerName: customerName,
          appointmentId: selectedAppointment.id,
          time: selectedAppointment.time
        })
      });
  
      const result = await response.json();
  
      if (result.success) {
        const updatedAppointment = {
          ...selectedAppointment,
          status: 'cancelled'
        };
        onAppointmentUpdate(updatedAppointment);
        
        showNotification(
          'success', 
          'Annulation confirmée', 
          selectedAppointment.reward 
            ? 'Le rendez-vous a été annulé et vos points ont été remboursés'
            : 'Le rendez-vous a été annulé avec succès'
        );
        setShowCancelModal(false);
      } else {
        throw new Error(result.message || 'Erreur lors de l\'annulation');
      }
    } catch (error) {
      console.error('Cancel error:', error);
      showNotification('error', 'Erreur', error.message || 'Impossible d\'annuler le rendez-vous');
    } finally {
      setIsLoading(false);
      setSelectedAppointment(null);
    }
  };

const handlePayPress = (appointment) => {
  if (!appointment.id && !appointment.docId) {
    console.error('No document ID found in appointment:', appointment);
    showNotification('Erreur: Impossible de traiter le paiement', 'error');
    return;
  }

  // Calculer le prix final
  let finalPrice = appointment.bookingDetails?.finalPrice;
  if (finalPrice === undefined || finalPrice === null) {
    finalPrice = appointment.finalPrice;
  }
  if (finalPrice === undefined || finalPrice === null) {
    finalPrice = appointment.service?.price;
  }

  setSelectedAppointment({
    ...appointment,
    docId: appointment.docId || appointment.id,
    finalPrice: finalPrice || 0
  });
  setShowPaymentModal(true);
};

const handlePaymentConfirm = async () => {
  try {
    setPaymentLoading(true);
    
    if (!selectedAppointment?.docId) {
      showNotification('error', 'Erreur', 'ID du rendez-vous manquant');
      return;
    }

    const response = await fetch(`${API_URL}/api/payments/create-checkout/${selectedAppointment.docId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    });

    const data = await response.json();
    
    if (data.success) {
      const paymentUrl = data.paymentLink;
      const canOpen = await Linking.canOpenURL(paymentUrl);
      
      if (canOpen) {
        await Linking.openURL(paymentUrl);
        setShowPaymentModal(false);
        
        let attempts = 0;
        const checkInterval = setInterval(async () => {
          if (attempts >= 24) {
            clearInterval(checkInterval);
            return;
          }

          try {
            const statusResponse = await fetch(
              `${API_URL}/api/payments/check-status/${selectedAppointment.docId}`
            );
            const statusData = await statusResponse.json();

            if (statusData.success && statusData.status === 'PAID') {
              clearInterval(checkInterval);
              showNotification('success', 'Paiement réussi', 'Le paiement a été accepté');
              onAppointmentUpdate({
                ...selectedAppointment,
                payment: { status: 'paid' }
              });
            }

            attempts++;
          } catch (error) {
            console.error('Erreur vérification statut:', error);
            clearInterval(checkInterval);
          }
        }, 5000);

      } else {
        showNotification('error', 'Erreur', 'Impossible d\'ouvrir la page de paiement');
      }
    } else {
      showNotification('error', 'Erreur', data.error || 'Erreur lors de la création du paiement');
    }
  } catch (error) {
    console.error('Erreur paiement:', error);
    showNotification('error', 'Erreur', 'Une erreur est survenue');
  } finally {
    setPaymentLoading(false);
  }
};

useEffect(() => {
  return () => {
    if (window.paymentCheckInterval) {
      clearInterval(window.paymentCheckInterval);
      window.paymentCheckInterval = null;
    }
  };
}, []);

useEffect(() => {
  if (!showPaymentModal && window.paymentCheckInterval) {
    clearInterval(window.paymentCheckInterval);
    window.paymentCheckInterval = null;
  }
}, [showPaymentModal]);

useEffect(() => {
  if (userData?.uid) {
    const unsubscribe = appointmentService.subscribeToAppointments(
      userData.uid,
      (newAppointments) => {
        // Forcer la mise à jour des rendez-vous
        setActiveTab(tab => {
          // On déclenche la mise à jour même si le tab ne change pas
          setTimeout(() => onAppointmentUpdate(newAppointments), 0);
          return tab;
        });
      }
    );

    return () => {
      unsubscribe();
    };
  }
}, [userData?.uid]);

useEffect(() => {
  setActiveTab(prev => prev); // Force re-render quand appointments change
}, [appointments]);

const PaymentModal = ({ visible, appointment, onClose, onAppointmentUpdate, showNotification }) => {
  const [paymentStarted, setPaymentStarted] = useState(false);
  
  useEffect(() => {
    if (!visible) {
      setPaymentStarted(false);
    }
  }, [visible]);

  if (!visible || !appointment) return null;

  if (appointment.finalPrice === 0) {
    onClose();
    return null;
  }

  const handleInitiatePayment = () => {
    Linking.openURL('https://tinyurl.com/mpp3kdzf');
    setPaymentStarted(true);
  };

  const handlePaymentComplete = async () => {
    try {
      // Mise à jour dans Firebase
      const appointmentRef = doc(db, 'appointments', appointment.id);
      
      // S'assurer d'avoir le bon prix final
      const finalPrice = appointment.finalPrice || appointment.service?.price || 0;
      
      await updateDoc(appointmentRef, {
        payment: {
          status: 'pending',
          finalPrice: finalPrice,
          updatedAt: new Date()
        }
      });
  
      // Seulement après la réussite de Firebase, on met à jour l'état local
      const updatedAppointment = {
        ...appointment,
        payment: {
          status: 'pending',
          finalPrice: finalPrice,
          updatedAt: new Date()
        }
      };
      
      onAppointmentUpdate(updatedAppointment);
      showNotification('success', 'Paiement en cours de vérification', 'Vérification du paiement');
      onClose();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du paiement:', error);
      showNotification('error', 'Erreur', "Une erreur est survenue lors de la mise à jour du paiement");
      // En cas d'erreur, on ne ferme pas le modal et on ne met pas à jour l'état
    }
  };

  const styles = {
    overlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      width: '90%',
      maxWidth: 360,
      height: 500,
      backgroundColor: 'white',
      borderRadius: 16,
      overflow: 'hidden',
      position: 'relative',
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: '#eee',
      position: 'relative',
    },
    title: {
      fontSize: 20,
      fontWeight: '600',
      color: '#1C2233',
      textAlign: 'center',
    },
    closeButton: {
      padding: 4,
      position: 'absolute',
      right: 16,
    },
    content: {
      padding: 16,
      flex: 1,
    },
    priceCard: {
      backgroundColor: '#1C2233',
      borderRadius: 16,
      padding: 20,
      alignItems: 'center',
      marginBottom: 24,
      shadowColor: '#1C2233',
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.1,
      shadowRadius: 8,
      elevation: 4,
    },
    priceLabel: {
      color: 'rgba(255,255,255,0.9)',
      marginBottom: 8,
      fontSize: 15,
      fontWeight: '500',
    },
    price: {
      color: 'white',
      fontSize: 36,
      fontWeight: '700',
      letterSpacing: 0.5,
    },
    instructionsContainer: {
      gap: 16,
    },
    instruction: {
      backgroundColor: '#f8f9fa',
      borderRadius: 12,
      padding: 16,
      flexDirection: 'row',
      alignItems: 'flex-start',
      borderWidth: 1,
      borderColor: '#1C223310',
    },
    instructionIcon: {
      marginRight: 12,
      marginTop: 2,
    },
    instructionText: {
      flex: 1,
      fontSize: 15,
      lineHeight: 22,
      color: '#1C2233',
    },
    highlight: {
      fontWeight: '700',
      color: '#1C2233',
    },
    bottomContainer: {
      padding: 16,
      borderTopWidth: 1,
      borderTopColor: '#eee',
    },
    payButton: {
      backgroundColor: '#1C2233',
      borderRadius: 12,
      padding: 16,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      shadowColor: '#1C2233',
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.1,
      shadowRadius: 8,
      elevation: 4,
    },
    payButtonText: {
      color: 'white',
      fontSize: 16,
      fontWeight: '600',
      marginRight: 8,
    },
    buttonContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 12,
    },
    secondaryButton: {
      flex: 1,
      backgroundColor: '#f8f9fa',
      borderRadius: 12,
      padding: 16,
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 1,
      borderColor: '#1C2233',
    },
    secondaryButtonText: {
      color: '#1C2233',
      fontSize: 14,
      fontWeight: '600',
    },
    successButton: {
      flex: 1,
      backgroundColor: '#28a745',
      borderRadius: 12,
      padding: 16,
      alignItems: 'center',
      justifyContent: 'center',
      shadowColor: '#28a745',
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.1,
      shadowRadius: 8,
      elevation: 4,
    },
    successButtonText: {
      color: 'white',
      fontSize: 14,
      fontWeight: '600',
    }
  };

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={onClose}
    >
      <View style={styles.overlay}>
        <View style={styles.modal}>
          <View style={styles.header}>
            <Text style={styles.title}>Paiement en avance</Text>
            <Pressable style={styles.closeButton} onPress={onClose}>
              <Ionicons name="close-outline" size={24} color="#1C2233" />
            </Pressable>
          </View>
          
          <View style={styles.content}>
            <View style={styles.priceCard}>
              <Text style={styles.priceLabel}>Montant à payer</Text>
              <Text style={styles.price}>
                {appointment.finalPrice || appointment.service?.price || 0}€
              </Text>
            </View>
            
            <View style={styles.instructionsContainer}>
              <View style={styles.instruction}>
                <Ionicons 
                  name="alert-circle" 
                  size={24} 
                  color="#1C2233" 
                  style={styles.instructionIcon} 
                />
                <Text style={styles.instructionText}>
                  Entre précisément{' '}
                  <Text style={styles.highlight}>{appointment.finalPrice}€</Text>
                  {' '}comme montant sur la page de paiement
                </Text>
              </View>

              <View style={styles.instruction}>
                <Ionicons 
                  name="create" 
                  size={24} 
                  color="#1C2233" 
                  style={styles.instructionIcon} 
                />
                <Text style={styles.instructionText}>
                  Indique{' '}
                  <Text style={styles.highlight}>{appointment.userInfo.firstName} {appointment.userInfo.lastName}</Text>
                  {' '}dans la remarque du paiement
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.bottomContainer}>
            {!paymentStarted ? (
              <TouchableOpacity 
                style={styles.payButton}
                onPress={handleInitiatePayment}
              >
                <Text style={styles.payButtonText}>
                  Continuer vers le paiement
                </Text>
                <Ionicons name="arrow-forward" size={20} color="white" />
              </TouchableOpacity>
            ) : (
              <View style={styles.buttonContainer}>
                <TouchableOpacity 
                  style={styles.secondaryButton}
                  onPress={onClose}
                >
                  <Text style={styles.secondaryButtonText}>
                    Annuler
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity 
                  style={styles.successButton}
                  onPress={handlePaymentComplete}
                >
                  <Text style={styles.successButtonText}>
                    Paiement effectué
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};


const memoizedUpcomingList = useMemo(() => (
  appointments.upcoming.map((appointment, index) => (
    <AnimatedAppointmentCard
      key={appointment.id}
      appointment={appointment}
      onCancelPress={handleCancelPress}
      onPayPress={handlePayPress}
      onPress={() => openModal(appointment)}
      index={index}
    />
  ))
), [appointments.upcoming, handleCancelPress, handlePayPress, openModal]);

const memoizedPastList = useMemo(() => (
  appointments.past.map((appointment, index) => (
    <AnimatedAppointmentCard
      key={appointment.id}
      appointment={appointment}
      onPress={openModal}
      index={index}
    />
  ))
), [appointments.past, openModal]);

return (
  <View style={styles.section}>
    <View style={styles.tabContainer} onLayout={onLayout}>
      <Animated.View 
        style={[
          styles.slider, 
          { 
            transform: [{ translateX }],
            width: tabWidth,
          }
        ]} 
      />
      <TouchableOpacity 
        style={styles.tab}
        onPress={() => animateSlide('upcoming')}
      >
        <Ionicons 
          name="calendar-outline"
          size={24} 
          color={activeTab === 'upcoming' ? colors.primary : colors.text} 
          style={styles.tabIcon}
        />
        <Text style={[styles.tabText, activeTab === 'upcoming' && styles.activeTabText]}>
          À venir ({appointments.upcoming.length})
        </Text>
      </TouchableOpacity>

      <TouchableOpacity 
        style={styles.tab}
        onPress={() => animateSlide('past')}
      >
        <Ionicons 
          name="time-outline" 
          size={24} 
          color={activeTab === 'past' ? colors.primary : colors.text}
          style={styles.tabIcon}
        />
        <Text style={[styles.tabText, activeTab === 'past' && styles.activeTabText]}>
          Historique ({appointments.past.length})
        </Text>
      </TouchableOpacity>
    </View>

    <View style={styles.appointmentList}>
      {activeTab === 'upcoming' ? (
        appointments.upcoming.length > 0 ? (
          memoizedUpcomingList
        ) : (
          <View style={styles.emptyState}>
            <Ionicons 
              name="calendar-outline" 
              size={48} 
              color={colors.text}
              style={styles.emptyIcon}
            />
            <Text style={styles.emptyText}>
              Aucun rendez-vous à venir
            </Text>
          </View>
        )
      ) : (
        appointments.past.length > 0 ? (
          memoizedPastList
        ) : (
          <View style={styles.emptyState}>
            <Ionicons 
              name="calendar-outline" 
              size={48} 
              color={colors.text}
              style={styles.emptyIcon}
            />
            <Text style={styles.emptyText}>
              Aucun rendez-vous passé
            </Text>
          </View>
        )
      )}
    </View>

    {showCancelModal && selectedAppointment && (
      <Modal
        visible={showCancelModal}
        transparent
        animationType="fade"
        onRequestClose={() => setShowCancelModal(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <View style={styles.modalHeader}>
              <View style={styles.modalTitleContainer}>
                <Text style={styles.modalTitle}>Confirmation</Text>
              </View>
              <Pressable style={styles.closeButton} onPress={() => setShowCancelModal(false)}>
                <Ionicons name="close-outline" size={28} color={colors.text} />
              </Pressable>
            </View>
            <View style={styles.separator} />

            <View style={styles.cancelModalBody}>
              <View style={styles.warningIconContainer}>
                <Ionicons name="alert-circle" size={48} color={colors.warning} />
              </View>
              <Text style={styles.cancelTitle}>Annuler le rendez-vous ?</Text>
              <Text style={styles.cancelMessage}>
                Es-tu sûr de vouloir annuler le rendez-vous du
              </Text>
              <Text style={styles.cancelDateTime}>
                {selectedAppointment.dateTime.toLocaleDateString('fr-FR', {
                  weekday: 'long',
                  day: 'numeric',
                  month: 'long'
                })}{' '}
                à {selectedAppointment.time}
              </Text>
              <Text style={styles.cancelWarning}>
                Cette action est irréversible.
              </Text>
            </View>

            <View style={styles.modalFooterContainer}>
              <View style={[styles.modalFooter, { borderTopWidth: 1, borderTopColor: '#E8E8E8', paddingTop: 20 }]}>
                <TouchableOpacity 
                  style={[styles.modalButton, styles.keepButton]}
                  onPress={() => setShowCancelModal(false)}
                  disabled={isLoading}
                >
                  <Text style={styles.buttonText}>Non, garder</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.modalButton, 
                    styles.confirmCancelButton,
                    isLoading && styles.disabledButton
                  ]}
                  onPress={handleCancelConfirm}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <ActivityIndicator color={colors.secondary} />
                  ) : (
                    <Text style={[styles.buttonText, styles.cancelButtonText]}>
                      Oui, annuler
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )}
    <PaymentModal
        visible={showPaymentModal}
        appointment={selectedAppointment}
        onClose={() => {
          setShowPaymentModal(false);
          setSelectedAppointment(null);
        }}
        onAppointmentUpdate={onAppointmentUpdate}
        showNotification={showNotification}
    />
    <Modal
      animationType="fade"
      transparent={true}
      visible={showInfoModal}
      onRequestClose={() => setShowInfoModal(false)}
    >
      <Pressable 
        style={{
          flex: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPress={() => setShowInfoModal(false)}
      >
        <Pressable style={{
          backgroundColor: 'white',
          borderRadius: 16,
          width: '92%',
          maxWidth: 360,
          alignSelf: 'center',
          overflow: 'hidden',
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 4 },
          shadowOpacity: 0.15,
          shadowRadius: 12,
          elevation: 8,
          padding: 12,
        }}>
          {selectedAppointment ? (
            <View>
              <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 8,
                borderBottomWidth: 1,
                borderBottomColor: colors.primary,
                paddingBottom: 8,
                position: 'relative',
              }}>
                <Text style={{
                  fontSize: 18,
                  fontWeight: '600',
                  color: colors.primary,
                  textAlign: 'center',
                }}>Détails du rendez-vous</Text>
                <TouchableOpacity 
                  onPress={() => setShowInfoModal(false)}
                  style={{
                    position: 'absolute',
                    right: 0,
                    backgroundColor: colors.lightGrey,
                    borderRadius: 12,
                    padding: 2,
                  }}
                >
                  <Ionicons name="close" size={18} color={colors.primary} />
                </TouchableOpacity>
              </View>

              <View style={{ flex: 1 }}>
                <View style={{
                  marginBottom: 8,
                  backgroundColor: colors.background,
                  padding: 10,
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: colors.primary,
                }}>
                  <View style={{
                    backgroundColor: colors.primary,
                    padding: 8,
                    borderRadius: 8,
                    marginBottom: 8,
                  }}>
                    <Text style={{
                      fontSize: 15,
                      color: 'white',
                      textAlign: 'center',
                      fontWeight: '600',
                    }}>{selectedAppointment.dateTime.toLocaleDateString('fr-FR', {
                      weekday: 'long',
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    })}</Text>
                    <Text style={{
                      fontSize: 13,
                      color: 'white',
                      textAlign: 'center',
                      marginTop: 2,
                    }}>à {selectedAppointment.time}</Text>
                  </View>

                  <View style={{
                    backgroundColor: 'white',
                    borderRadius: 8,
                    padding: 8,
                    marginBottom: 6,
                  }}>
                    <Text style={{
                      fontSize: 14,
                      color: colors.text,
                      textAlign: 'center',
                      marginBottom: 2,
                      fontWeight: '600',
                    }}>Prestation</Text>
                    <Text style={{
                      fontSize: 13,
                      color: colors.primary,
                      textAlign: 'center',
                      fontWeight: '500',
                    }}>{selectedAppointment.service?.name || 'Non spécifiée'}</Text>
                  </View>

                  <View style={{
                    backgroundColor: 'white',
                    borderRadius: 8,
                    padding: 8,
                    marginBottom: 6,
                  }}>
                    <Text style={{
                      fontSize: 14,
                      color: colors.text,
                      textAlign: 'center',
                      marginBottom: 2,
                      fontWeight: '600',
                    }}>Prix</Text>
                    
                    <Text style={{
                      fontSize: 13,
                      color: colors.primary,
                      textAlign: 'center',
                      fontWeight: '500',
                    }}>
                      {selectedAppointment.reward ? (
                        <>
                          <Text style={{ textDecorationLine: 'line-through' }}>
                            {selectedAppointment.service?.price || 0}€
                          </Text>
                          {' '}
                          <Text>{selectedAppointment.finalPrice || selectedAppointment.service?.price || 0}€</Text>
                          {'\n'}
                          <Text style={{ fontSize: 12, color: colors.success }}>
                            Réduction appliquée : -{selectedAppointment.reward.discount}€
                          </Text>
                        </>
                      ) : (
                        `${selectedAppointment.finalPrice || selectedAppointment.service?.price || 0}€`
                      )}
                    </Text>
                  </View>

                  {selectedAppointment.bookingDetails?.supplements?.length > 0 && (
                    <View style={{
                      backgroundColor: 'white',
                      borderRadius: 8,
                      padding: 8,
                      marginBottom: 6,
                    }}>
                      <Text style={{
                        fontSize: 14,
                        color: colors.text,
                        textAlign: 'center',
                        marginBottom: 2,
                        fontWeight: '600',
                      }}>Suppléments</Text>
                      {selectedAppointment.bookingDetails.supplements.map((supplement, index) => (
                        <Text key={index} style={{
                          fontSize: 13,
                          color: colors.primary,
                          textAlign: 'center',
                          fontWeight: '500',
                        }}>{supplement.name} (+{supplement.price}€)</Text>
                      ))}
                    </View>
                  )}

                  <View style={{
                    backgroundColor: 'white',
                    borderRadius: 8,
                    padding: 8,
                  }}>
                    <Text style={{
                      fontSize: 14,
                      color: colors.text,
                      textAlign: 'center',
                      marginBottom: 2,
                      fontWeight: '600',
                    }}>Statut</Text>
                    <View style={{
                      backgroundColor: 
                        selectedAppointment.status === 'scheduled' ? colors.primary : 
                        selectedAppointment.status === 'cancelled' ? colors.error :
                        '#6c757d',
                      paddingVertical: 3,
                      paddingHorizontal: 8,
                      borderRadius: 10,
                      alignSelf: 'center',
                    }}>
                      <Text style={{
                        fontSize: 12,
                        color: 'white',
                        textAlign: 'center',
                        fontWeight: '600',
                      }}>{
                        selectedAppointment.status === 'scheduled' ? 'Planifié' : 
                        selectedAppointment.status === 'cancelled' ? 'Annulé' : 
                        'Terminé'
                      }</Text>
                    </View>
                  </View>
                </View>

                <View style={{
                  marginBottom: 8,
                  backgroundColor: colors.background,
                  padding: 10,
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: colors.primary,
                }}>
                  <Text style={{
                    fontSize: 14,
                    color: colors.text,
                    textAlign: 'center',
                    marginBottom: 4,
                    fontWeight: '600',
                  }}>Adresse</Text>
                  <Text style={{
                    fontSize: 13,
                    color: colors.primary,
                    textAlign: 'center',
                    lineHeight: 18,
                  }}>1 Rue des Alpes{"\n"}72100 Le Mans</Text>
                  <Text style={{
                    fontSize: 13,
                    color: colors.text,
                    textAlign: 'center',
                    marginTop: 8,
                    lineHeight: 18,
                  }}>Code Interphone : 1806{"\n"}3ème étage{"\n"}Porte 1806</Text>
                </View>
              </View>

              <TouchableOpacity
                style={{
                  backgroundColor: '#E1306C',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 10,
                  borderRadius: 8,
                  marginTop: 'auto',
                }}
                onPress={openInstagram}
              >
                <Ionicons name="logo-instagram" size={18} color="white" />
                <Text style={{
                  color: 'white',
                  fontSize: 13,
                  fontWeight: '600',
                  marginLeft: 8,
                }}>Besoin d'aide ?</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <Text style={{ fontSize: 16, textAlign: 'center' }}>Aucun rendez-vous sélectionné</Text>
          )}
        </Pressable>
      </Pressable>
    </Modal>
  </View>
);
});

export default AppointmentHistory;