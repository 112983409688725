// pointsService.js
import { db } from '../firebase';
import { 
  doc, 
  getDoc, 
  runTransaction,
  collection, 
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
  Timestamp
} from 'firebase/firestore';

const isItemAffectedByReward = (itemId, reward) => {
  if (!reward) return false;

  switch (reward.id) {
    case 'half-off':
      return itemId === 'coupe'; // Seulement la coupe simple
    case 'free-cut':
      return itemId === 'coupe'; // Seulement la coupe simple
    case 'free-cut-beard':
      return itemId === 'coupe-barbe';
    case 'free-design':
      return itemId === 'design';
    case 'free-enhancement':
      return itemId === 'enhancement-pro';
    default:
      return false;
  }
};

const pointsService = {
  async processBookingPoints(userEmail, bookingData) {
    if (!userEmail) {
      console.error('No user email provided');
      throw new Error('Email utilisateur manquant');
    }

    try {
      console.log('Processing booking points with data:', bookingData);
      
      // Vérifications initiales
      if (!bookingData.service || typeof bookingData.service.points !== 'number') {
        console.error('Service ou points invalides:', bookingData.service);
        throw new Error('Configuration de service invalide');
      }

      // Configuration du service avec les points existants
      const serviceData = {
        id: bookingData.service.id,
        name: bookingData.service.name,
        points: bookingData.service.points,
        price: bookingData.service.price
      };

      // Mise à jour de bookingData
      bookingData.service = serviceData;

      // Recherche de l'utilisateur
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', userEmail));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('Utilisateur non trouvé');
      }

      const userDoc = querySnapshot.docs[0];
      const userRef = doc(db, 'users', userDoc.id);
      let historyEntries = [];

      await runTransaction(db, async (transaction) => {
        const userSnapshot = await transaction.get(userRef);
        
        if (!userSnapshot.exists()) {
          throw new Error('Utilisateur non trouvé');
        }

        const currentPoints = parseInt(userSnapshot.data().loyaltyPoints) || 0;
        const isVIP = userSnapshot.data().isVIP || false;
        let newPoints = currentPoints;
        const timestamp = Timestamp.now();

        // Gestion des points de récompense (inchangée)
        if (bookingData.reward && !bookingData.isVIPFreeHaircut && bookingData.status === 'scheduled') {
          if (currentPoints < bookingData.reward.points) {
            throw new Error('Points insuffisants');
          }

          newPoints = currentPoints - bookingData.reward.points;
          console.log('Points déduits:', -bookingData.reward.points);

          // Historique de la réduction
          const historyDoc = doc(collection(db, 'pointsHistory'));
          const historyEntry = {
            userId: userDoc.id,
            userEmail,
            pointsChange: -bookingData.reward.points,
            type: 'spent',
            description: `Utilisation récompense : ${bookingData.reward.name}`,
            timestamp,
            serviceId: serviceData.id,  // Utiliser l'ID du service validé
            serviceName: serviceData.name,
            finalPoints: newPoints,
            rewardId: bookingData.reward.id || 'unknown',
            rewardName: bookingData.reward.name || 'Récompense',
            bookingDetails: {
              service: serviceData,
              reward: bookingData.reward,
              supplements: bookingData.bookingDetails?.supplements?.map(s => ({
                id: s.id || 'unknown',
                name: s.name || 'Supplément',
                points: typeof s.points === 'number' ? s.points : 0,
                price: s.price || 0
              })) || []
            }
          };
          historyEntries.push({ doc: historyDoc, data: historyEntry });
        }

        // Nouvelle logique pour l'attribution des points
        const isPaid = bookingData.payment?.status === 'paid';
        const isCompleted = bookingData.status === 'completed';

        console.log('État du rendez-vous:', {
          isPaid,
          isCompleted,
          servicePoints: serviceData.points,
          isVIP
        });

        // On calcule et attribue les points dès que les deux conditions sont remplies
        if (isPaid && isCompleted && !bookingData.pointsProcessed) {
          let pointsToEarn = 0;
          
          // Calcul des points du service principal
          if (serviceData && !isItemAffectedByReward(serviceData.id, bookingData.reward)) {
            const basePoints = serviceData.points;
            const servicePoints = isVIP ? basePoints * 2 : basePoints;
            pointsToEarn += servicePoints;
            console.log(`Points service ${serviceData.name}:`, servicePoints, '(base:', basePoints, 'VIP:', isVIP, ')');
          }

          // Calcul des points des suppléments
          if (bookingData.bookingDetails?.supplements?.length > 0) {
            bookingData.bookingDetails.supplements.forEach(supplement => {
              if (!isItemAffectedByReward(supplement.id, bookingData.reward)) {
                const basePoints = supplement.points;
                if (typeof basePoints === 'number') {
                  const supplementPoints = isVIP ? basePoints * 2 : basePoints;
                  pointsToEarn += supplementPoints;
                  console.log(`Points supplément ${supplement.name}:`, supplementPoints, '(base:', basePoints, 'VIP:', isVIP, ')');
                }
              }
            });
          }

          // Attribution des points uniquement si pas déjà traités
          if (pointsToEarn > 0) {
            newPoints = currentPoints + pointsToEarn;
            
            // Création de l'entrée d'historique pour les points gagnés
            const historyDoc = doc(collection(db, 'pointsHistory'));
            historyEntries.push({
              doc: historyDoc,
              data: {
                userId: userDoc.id,
                userEmail,
                pointsChange: pointsToEarn,
                type: 'earned',
                description: `Points gagnés${isVIP ? ' (VIP)' : ''} : ${serviceData.name}`,
                timestamp,
                serviceId: serviceData.id,
                serviceName: serviceData.name,
                finalPoints: newPoints,
                isVIP,
                bookingDetails: {
                  service: serviceData,
                  supplements: bookingData.bookingDetails?.supplements || []
                }
              }
            });

            // Mettre à jour le statut des points dans l'appointment
            if (bookingData.id) {
              const appointmentRef = doc(db, 'appointments', bookingData.id);
              transaction.update(appointmentRef, {
                pointsProcessed: true,
                pointsProcessedAt: Timestamp.now()
              });
            }
          }
        }

        console.log('Updating points:', { 
          currentPoints, 
          newPoints,
          pointsChange: newPoints - currentPoints,
          isVIP
        });

        // Mettre à jour les points si changement
        if (newPoints !== currentPoints) {
          transaction.update(userRef, {
            loyaltyPoints: newPoints.toString()
          });

          // Créer les entrées d'historique
          historyEntries.forEach(entry => {
            transaction.set(entry.doc, entry.data);
          });
        }
      });

      return { success: true };
    } catch (error) {
      console.error('Erreur lors du traitement des points :', error);
      throw error;
    }
  }
};

export { pointsService };