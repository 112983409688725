import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Platform, Animated, Alert, RefreshControl, Dimensions, Modal } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import globalStyles, { colors } from '../screenStyles/GlobalStyles';
import styles from '../screenStyles/FideliteStyles';
import stylesReservation from '../screenStyles/ReservationStyles';
import CustomButton from '../components/CustomButton';
import { auth } from '../firebase';
import { db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';



const VIPIndicator = ({ isVIP }) => {
  if (!isVIP) return null;

  return (
    <View style={styles.vipBanner}>
      <Text style={styles.vipText}>👑 Compte VIP Actif</Text>
    </View>
  );
};

const ServiceCard = ({ title, price, points, onPress }) => (
  <TouchableOpacity 
    style={styles.serviceCard}
    onPress={onPress}
    activeOpacity={0.7}
  >
    <View style={styles.serviceInfo}>
      <Text style={styles.serviceName}>{title}</Text>
      <Text style={styles.servicePrice}>{price}€</Text>
      <View style={styles.pointsBadge}>
        <Text style={styles.pointsText}>+{points} points 💫</Text>
      </View>
    </View>
  </TouchableOpacity>
);

const RewardCard = ({ points, description, currentPoints, onPress }) => {
  const pointsNeeded = points - currentPoints;
  const isAvailable = currentPoints >= points;

  return (
    <TouchableOpacity 
      style={[
        styles.rewardCard,
        isAvailable && { borderColor: colors.accent, borderWidth: 2 }
      ]}
      activeOpacity={0.7}
    >
      <View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={styles.serviceName}>{points} points</Text>
          {isAvailable && (
            <View style={styles.availableBadge}>
              <Text style={styles.availableText}>Disponible ✨</Text>
            </View>
          )}
        </View>
        <Text style={styles.servicePrice}>{description}</Text>
        {!isAvailable && (
          <View style={styles.pointsNeeded}>
            <Text style={styles.pointsNeededText}>
              Plus que {pointsNeeded} points nécessaires
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

const PremiumSubscriptionModal = ({ visible, onClose, isVIP }) => {
  const { Linking } = require('react-native');
  
  const handleSubscription = () => {
    Linking.openURL('https://pay.sumup.com/b2c/QFG16TK0').catch(err => {
      console.error('Erreur lors de l\'ouverture du lien:', err);
      Alert.alert('Erreur', 'Impossible d\'ouvrir la page de paiement');
    });
    onClose();
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={() => {}}
    >
      <View style={styles.modalOverlay}>
        <TouchableOpacity 
          style={{flex: 1, width: '100%'}}
          activeOpacity={1}
          onPress={onClose}
        >
          <View style={{flex: 1}} />
        </TouchableOpacity>

        <View style={styles.modalContent}>
          <TouchableOpacity 
            style={styles.closeButton}
            onPress={onClose}
          >
            <Ionicons name="close" size={24} color="#fff" />
          </TouchableOpacity>

          {/* En-tête fixe */}
          <View style={styles.modalHeader}>
            <Text style={styles.modalHeaderTitle}>👑 Abonnement VIP 👑</Text>
            <Text style={styles.modalHeaderPrice}>35€/mois</Text>
          </View>

          {/* Contenu scrollable avec padding bottom pour le bouton fixe */}
          <ScrollView 
            showsVerticalScrollIndicator={false}
            contentContainerStyle={styles.modalScrollContent}
          >
            <View style={styles.modalVisualSection}>
              <View style={styles.visualCard}>
                <Ionicons name="cut-outline" size={24} color={colors.primary} />
                <Text style={styles.visualCardText}>Coupes illimitées</Text>
              </View>
              <View style={styles.visualCard}>
                <Ionicons name="calendar-outline" size={24} color={colors.primary} />
                <Text style={styles.visualCardText}>Prioritaire</Text>
              </View>
              <View style={styles.visualCard}>
                <Ionicons name="star-outline" size={24} color={colors.primary} />
                <Text style={styles.visualCardText}>Points x2</Text>
              </View>
            </View>

            <View style={styles.modalSection}>
              <Text style={styles.modalSectionTitle}>✨ Avantages inclus</Text>
              <Text style={styles.modalFeature}>🎯 Coupes simples illimitées pendant 30 jours</Text>
              <Text style={styles.modalFeature}>📅 Réservation prioritaire</Text>
              <Text style={styles.modalFeature}>🌟 Points de fidélité doublés</Text>
              <Text style={styles.modalFeature}>🎁 Promotions exclusives VIP</Text>
            </View>

            <View style={styles.modalSection}>
              <Text style={styles.modalSectionTitle}>📝 Conditions</Text>
              <Text style={styles.modalFeature}>✓ Valable 30 jours après paiement</Text>
              <Text style={styles.modalFeature}>✓ Non transférable</Text>
              <Text style={styles.modalFeature}>✓ Sur rendez-vous uniquement</Text>
              <Text style={styles.modalFeature}>✓ Non remboursable</Text>
            </View>
          </ScrollView>

          {/* Footer fixe avec bouton */}
          <View style={styles.modalFooter}>
            <TouchableOpacity 
              style={[styles.modalButton, isVIP && { backgroundColor: 'gray' }]}
              disabled={isVIP}
              onPress={!isVIP ? handleSubscription : null}
            >
              <Text style={[styles.modalButtonText, { color: '#fff', fontSize: 16, fontWeight: 'bold' }]}>
                {isVIP ? 'Déjà membre VIP' : 'Je suis intéressé'}
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        <TouchableOpacity 
          style={{flex: 1, width: '100%'}}
          activeOpacity={1}
          onPress={onClose}
        >
          <View style={{flex: 1}} />
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

const SubscriptionCard = ({ isVIP }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <View style={styles.subscriptionCard}>
      <Text style={styles.subscriptionTitle}>👑 Abonnement VIP 👑</Text>
      <Text style={styles.subscriptionPrice}>35€/mois</Text>
      <View style={styles.subscriptionFeatures}>
        <Text style={styles.featureText}>• 🎯 Coupes gratuites illimitées pendant 30 jours</Text>
        <Text style={styles.featureText}>• 📅 Non transférable, uniquement sur rendez-vous</Text>
        <Text style={styles.featureText}>• 🌟 Points de fidélité doublés</Text>
      </View>
      <TouchableOpacity 
        style={styles.subscriptionButton}
        onPress={() => setModalVisible(true)}
      >
        <Text style={styles.subscriptionButtonText}>En savoir plus</Text>
      </TouchableOpacity>
      <PremiumSubscriptionModal visible={modalVisible} onClose={() => setModalVisible(false)} isVIP={isVIP} />
    </View>
  );
};

const HistoryCard = ({ transaction }) => {
  if (transaction.pointsChange === 0) return null;

  const isPositive = transaction.pointsChange > 0;
  
  // Construire la description selon le type de transaction
  let mainDescription = '';
  let details = '';
  let vipMultiplier = '';

  if (transaction.type === 'refund') {
    mainDescription = 'Annulation du RDV';
    if (transaction.bookingDetails?.reward?.name) {
      details = `Réduc remboursée : ${transaction.bookingDetails.reward.name}`;
    }
  } else if (transaction.type === 'spent') {
    mainDescription = transaction.rewardName || 'Récompense utilisée';
    if (transaction.bookingDetails?.service?.name) {
      details = `Pour : ${transaction.bookingDetails.service.name}`;
    }
  } else {
    if (transaction.bookingDetails?.service?.name) {
      mainDescription = transaction.bookingDetails.service.name;
      if (transaction.isVIP) {
        vipMultiplier = '(Points x2 VIP)';
      }
      if (transaction.bookingDetails.supplements?.length > 0) {
        details = 'Suppléments : ' + transaction.bookingDetails.supplements
          .map(supp => supp.name)
          .join(', ');
      }
    } else {
      mainDescription = transaction.description
        ?.replace(/^Points (gagnés|utilisés) : /, '')
        ?.replace(/^Utilisation récompense : /, '') || '';
    }
  }

  return (
    <View style={[
      styles.historyCard,
      { borderLeftWidth: 4, borderLeftColor: isPositive ? colors.accent : colors.secondary }
    ]}>
      <View style={styles.transactionHeader}>
        <Text style={styles.transactionDescription}>
          {transaction.type === 'refund' 
            ? 'Points remboursés ↩️' 
            : isPositive ? 'Points gagnés ✨' : 'Points utilisés 🎁'}
        </Text>
        <Text style={[
          styles.pointsChange,
          isPositive ? styles.pointsPositive : styles.pointsNegative
        ]}>
          {isPositive ? '+' : ''}{transaction.pointsChange} points
        </Text>
      </View>
      
      <View style={styles.transactionContent}>
        <Text style={[styles.detailText, styles.mainService]}>
          {mainDescription}
          {vipMultiplier && (
            <Text style={styles.vipMultiplier}> {vipMultiplier}</Text>
          )}
        </Text>
        
        {details && (
          <Text style={styles.supplementText}>
            {details}
          </Text>
        )}
      </View>
      
      <View style={styles.detailRow}>
        <Text style={styles.detailText}>
          {new Date(transaction.timestamp).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })} à {new Date(transaction.timestamp).toLocaleTimeString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit'
          })}
        </Text>
      </View>
      
      <View style={styles.detailRow}>
        <Text style={styles.finalPoints}>
          Solde : {transaction.finalPoints} points 💫
        </Text>
      </View>
    </View>
  );
};

const AnimatedHistoryCard = ({ transaction, index }) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(20)).current;

  useEffect(() => {
    Animated.sequence([
      Animated.delay(index * 100), // Délai progressif selon la position
      Animated.parallel([
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false,
        }),
        Animated.spring(translateY, {
          toValue: 0,
          tension: 50,
          friction: 7,
          useNativeDriver: false,
        })
      ])
    ]).start();
  }, []);

  return (
    <Animated.View style={{
      opacity: fadeAnim,
      transform: [{ translateY }]
    }}>
      <HistoryCard transaction={transaction} />
    </Animated.View>
  );
};

const PointsCard = ({ points, scaleAnim }) => (
  <View style={styles.pointsCard}>
    <Text style={styles.pointsLabel}>Mes Points de fidélité</Text>
    <Animated.Text style={[
      styles.pointsValue,
      { transform: [{ scale: scaleAnim }] }
    ]}>
      ✨ {points} points ✨
    </Animated.Text>
  </View>
);

const FideliteScreen = () => {
  const route = useRoute();
  const scrollViewRef = useRef(null);
  const subscriptionCardRef = useRef(null);
  const navigation = useNavigation();
  const [activeTab, setActiveTab] = useState('history');
  const [initialTab] = useState(route.params?.activeTab || 'history');
  const slideAnim = useRef(new Animated.Value(
    initialTab === 'history' ? 0 : initialTab === 'services' ? 1 : 2
  )).current;
  const [isVIP, setIsVIP] = useState(false);
  const [currentPoints, setCurrentPoints] = useState(null);
  const [history, setHistory] = useState([]);
  const [scaleAnim] = useState(new Animated.Value(1));
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);
  
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const [refreshing, setRefreshing] = useState(false);
  const previousPoints = useRef(null);
  const isFirstLoad = useRef(true);
  const [tabPositions, setTabPositions] = useState({
    history: { x: 0, width: 0 },
    services: { x: 0, width: 0 },
    rewards: { x: 0, width: 0 }
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      if (userDoc.exists()) {
        setIsVIP(userDoc.data().isVIP || false);
      }
    };
  
    fetchUserData();
  }, []);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      // Gérer la navigation vers l'onglet récompenses
      if (route.params?.activeTab === 'rewards') {
        setActiveTab('rewards');
        
        // Si scrollToVIP est true, faire défiler jusqu'à la carte d'abonnement
        if (route.params?.scrollToVIP) {
          setTimeout(() => {
            scrollViewRef.current?.scrollToEnd({ animated: true });
          }, 500);
        }
      }
    }, [route.params])
  );

  useFocusEffect(
    React.useCallback(() => {
      if (currentPoints !== null && !isFirstLoad.current) {
        triggerAnimation();
      }
    }, [currentPoints])
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        setCurrentPoints(0);
        setHistory([]);
      }
    });
    return () => unsubscribe();
  }, []);

  const triggerAnimation = () => {
    scaleAnim.setValue(1);
    Animated.sequence([
      Animated.timing(scaleAnim, {
        toValue: 1.2,
        duration: 200,
        useNativeDriver: false
      }),
      Animated.spring(scaleAnim, {
        toValue: 1,
        friction: 4,
        tension: 40,
        useNativeDriver: false
      })
    ]).start();
  };
  
  useEffect(() => {
    if (route.params?.activeTab === 'rewards') {
      setActiveTab('rewards');
      animateSlide('rewards');
      
      if (route.params?.scrollToVIP) {
        // Attendre que le rendu soit terminé avant de scroller
        setTimeout(() => {
          if (subscriptionCardRef.current) {
            subscriptionCardRef.current.measureInWindow((x, y) => {
              scrollViewRef.current?.scrollTo({
                y: y - 100, // Offset pour voir un peu au-dessus de la carte
                animated: true
              });
            });
          }
        }, 500);
      }
    }
  }, [route.params]);



  const animatePointsUpdate = (newPoints) => {
    if (previousPoints.current === newPoints) return;
    
    previousPoints.current = newPoints;
    setCurrentPoints(newPoints);
    
    if (!isFirstLoad.current) {
      triggerAnimation();
    }
  };

  useEffect(() => {
    let unsubscribeUser = null;
    let unsubscribeHistory = null;

    const setupRealtimeListeners = async () => {
      const user = auth.currentUser;
      if (!user) return;

      // Écouteur en temps réel pour les données utilisateur
      unsubscribeUser = onSnapshot(doc(db, 'users', user.uid), (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          const points = parseInt(userData.loyaltyPoints) || 0;
          const userVIPStatus = userData.isVIP || false;

          if (isFirstLoad.current) {
            setCurrentPoints(points);
            previousPoints.current = points;
            setIsVIP(userVIPStatus);
            isFirstLoad.current = false;
          } else {
            animatePointsUpdate(points);
            setIsVIP(userVIPStatus);
          }
        }
      }, (error) => {
        console.error("Erreur dans l'écouteur utilisateur:", error);
      });

      // Écouteur en temps réel pour l'historique des points
      const historyRef = collection(db, 'pointsHistory');
      const q = query(
        historyRef,
        where('userEmail', '==', user.email)
      );

      unsubscribeHistory = onSnapshot(q, (snapshot) => {
        const historyData = snapshot.docs
          .map(doc => {
            const data = doc.data();
            let supplementDetails = '';
            if (data.bookingDetails?.supplements?.length > 0) {
              supplementDetails = data.bookingDetails.supplements.map(s => s.name).join(', ');
            } else if (data.bookingDetails?.supplement?.name) {
              supplementDetails = data.bookingDetails.supplement.name;
            }

            return {
              id: doc.id,
              ...data,
              supplementDetails,
              timestamp: data.timestamp?.toDate() || new Date(),
              finalPoints: parseInt(data.finalPoints) || 0,
              pointsChange: parseInt(data.pointsChange) || 0,
            };
          })
          .sort((a, b) => b.timestamp - a.timestamp)
          .slice(0, 50);

        setHistory(historyData);
        setIsHistoryLoading(false);
        setRefreshing(false);
      }, (error) => {
        console.error("Erreur dans l'écouteur historique:", error);
        setIsHistoryLoading(false);
        setRefreshing(false);
      });
    };

    setupRealtimeListeners();

    // Nettoyage des écouteurs
    return () => {
      if (unsubscribeUser) unsubscribeUser();
      if (unsubscribeHistory) unsubscribeHistory();
    };
  }, [auth.currentUser]);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    // Les données seront automatiquement mises à jour via les écouteurs
    setTimeout(() => setRefreshing(false), 1000);
  }, []);

  const measureTab = (event, tabName) => {
    const { x, width } = event.nativeEvent.layout;
    setTabPositions(prev => ({
      ...prev,
      [tabName]: { x, width }
    }));
  };

  const getSliderPosition = (tabIndex) => {
    const tabNames = ['history', 'services', 'rewards'];
    const currentTab = tabNames[tabIndex];
    const position = tabPositions[currentTab];
    
    if (position.width) {
      const tabWidth = position.width;
      return position.x + (tabWidth * 0.12); // Ajusté à 12%
    }
    
    return tabIndex * (dimensions.width / 3);
  };

  const getSliderWidth = (tabIndex) => {
    const tabNames = ['history', 'services', 'rewards'];
    const currentTab = tabNames[tabIndex];
    const position = tabPositions[currentTab];
    
    if (position.width) {
      return position.width * 0.7; // 70% de la largeur de l'onglet
    }
    
    return dimensions.width / 3;
  };

  const animateSlide = (tab) => {
    const toValue = tab === 'history' ? 0 : tab === 'services' ? 1 : 2;
    Animated.spring(slideAnim, {
      toValue,
      friction: 8,
      tension: 50,
      useNativeDriver: false,
    }).start();
    setActiveTab(tab);
  };


  const renderHistory = () => (
    <View style={styles.section}>
      <Text style={[styles.sectionTitle, { textAlign: 'center' }]}>Historique des Points</Text>
      <Text style={styles.sectionDescription}>
        Suis tes transactions et tes récompenses
      </Text>
      {isHistoryLoading ? null : (
        history.length === 0 ? (
          <View style={styles.emptyState}>
            <Ionicons 
              name="document-text-outline" 
              size={48} 
              color="#666" 
              style={styles.emptyIcon}
            />
            <Text style={styles.emptyText}>Pas encore d'historique</Text>
            <Text style={styles.emptySubtext}>
              Toutes les transactions de points apparaîtront ici
            </Text>
          </View>
        ) : (
          history.map((transaction, index) => {
            if (!transaction.finalPoints && index > 0) {
              transaction.finalPoints = history[index - 1].finalPoints + transaction.pointsChange;
            } else if (!transaction.finalPoints) {
              transaction.finalPoints = currentPoints;
            }
    
            return (
              <AnimatedHistoryCard 
                key={transaction.id} 
                transaction={transaction}
                index={index}
              />
            );
          })
        )
      )}
    </View>
  );

  const renderServices = () => {
    const sortedServices = [
      { title: "✂️ Coupe", price: "10", points: 50 },
      { title: "✂️ Coupe + 🧔‍♂️ Barbe", price: "15", points: 75 },
      { title: "✨ Contours", price: "5", points: 25 },
      { title: "💫 Fibre Noir sans fixateur", price: "2", points: 10 },
      { title: "⭐ Fibre Noir avec fixateur", price: "5", points: 25 },
      { title: "🎨 Design/Trait simple", price: "2", points: 10 },
    ].sort((a, b) => a.points - b.points);

    return (
      <View style={styles.section}>
        <Text style={[styles.sectionTitle, { textAlign: 'center' }]}>Services et Points</Text>
        <Text style={styles.sectionDescription}>
          Gagne des points à chaque service !
        </Text>
        {sortedServices.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            price={service.price}
            points={service.points}
          />
        ))}
      </View>
    );
  };

  const renderRewards = () => {
    const sortedRewards = [
      { points: 125, description: "🎁 -50% sur une coupe (5€ de réduction)" },
      { points: 250, description: "✨ Une coupe gratuite" },
      { points: 375, description: "🌟 Coupe + Barbe gratuite" },
      { points: 50, description: "🎨 Design simple gratuit" },
      { points: 150, description: "💫 Fibre Noir avec fixateur gratuit" },
    ].sort((a, b) => a.points - b.points);

    return (
      <View style={styles.section}>
        <Text style={[styles.sectionTitle, { textAlign: 'center' }]}>Récompenses Disponibles</Text>
        <Text style={styles.sectionDescription}>
          Échange tes points contre des récompenses !
        </Text>
        {sortedRewards.map((reward, index) => (
          <RewardCard
            key={index}
            points={reward.points}
            description={reward.description}
            currentPoints={currentPoints}
          />
        ))}
      </View>
    );
  };

  if (!auth.currentUser) {
    return (
      <View style={globalStyles.container}>
        <View style={[stylesReservation.centerContent, { flex: 1 }]}>
          <Ionicons 
            name="person-circle-outline" 
            size={64} 
            color={colors.primary}
            style={stylesReservation.loginIcon}
          />
          <Text style={stylesReservation.loginTitle}>Connexion requise</Text>
          <Text style={stylesReservation.loginMessage}>
            Connecte-toi pour accéder au programme de fidélité
          </Text>
          <View style={stylesReservation.buttonContainer}>
            <CustomButton
              title="Se connecter"
              onPress={() => navigation.navigate('Compte', { mode: 'login' })}
              style={stylesReservation.loginButton}
            />
            <CustomButton
              title="S'inscrire"
              color={colors.secondary}  // Ajout de cette prop
              onPress={() => {
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'Compte', params: { mode: 'register', fromReservation: true } }],
                });
              }}
              style={[stylesReservation.loginButton, { marginTop: 10 }]}
              type="secondary"
            />
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={globalStyles.container}>
      <ScrollView 
        ref={scrollViewRef}
        style={globalStyles.scrollView}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={[colors.primary]}
          />
        }
        contentContainerStyle={{ 
          flexGrow: 1,
          paddingBottom: Platform.OS === 'web' ? 55 : 85 // Augmentation des valeurs
        }}
      >

        <PointsCard 
          points={currentPoints ?? 0}
          scaleAnim={scaleAnim}
        />
        
        <View 
          style={styles.tabContainer}
        >
          <Animated.View 
            style={[
              styles.slider, 
              {
                width: getSliderWidth(
                  activeTab === 'history' ? 0 : activeTab === 'services' ? 1 : 2
                ),
                transform: [{ translateX: slideAnim.interpolate({
                  inputRange: [0, 1, 2],
                  outputRange: [
                    getSliderPosition(0),
                    getSliderPosition(1),
                    getSliderPosition(2)
                  ]
                }) }],
              },
            ]} 
          />
          <TouchableOpacity 
            style={styles.tab}
            onPress={() => animateSlide('history')}
            onLayout={(event) => measureTab(event, 'history')}
          >
            <View style={styles.tabContent}>
              <Ionicons 
                name="time-outline" 
                size={18}
                color={activeTab === 'history' ? colors.secondary : colors.text} 
              />
              <Text style={[
                styles.tabText,
                activeTab === 'history' && styles.activeTabText
              ]}>
                Historique
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity 
            style={styles.tab}
            onPress={() => animateSlide('services')}
            onLayout={(event) => measureTab(event, 'services')}
          >
            <View style={styles.tabContent}>
              <Ionicons 
                name="cut" 
                size={18}
                color={activeTab === 'services' ? colors.secondary : colors.text} 
              />
              <Text style={[
                styles.tabText,
                activeTab === 'services' && styles.activeTabText
              ]}>
                Services
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity 
            style={styles.tab}
            onPress={() => animateSlide('rewards')}
            onLayout={(event) => measureTab(event, 'rewards')}
          >
            <View style={styles.tabContent}>
              <Ionicons 
                name="gift" 
                size={18}
                color={activeTab === 'rewards' ? colors.secondary : colors.text} 
              />
              <Text style={[
                styles.tabText,
                activeTab === 'rewards' && styles.activeTabText
              ]}>
                Récompenses
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        
        {activeTab === 'history' && renderHistory()}
        {activeTab === 'services' && renderServices()}
        {activeTab === 'rewards' && (
          <View style={[styles.section, { marginBottom: Platform.OS === 'web' ? 40 : 20 }]}>
            {renderRewards()}
            <View ref={subscriptionCardRef}>
              <SubscriptionCard isVIP={isVIP} />
            </View>
          </View>
        )}
        {/* Ajout de l'espaceur en bas avec une marge supplémentaire */}
        <View style={[globalStyles.bottomSpacer, { marginTop: Platform.OS === 'web' ? 40 : 20 }]} />
      </ScrollView>
    </View>
  );
};

export default FideliteScreen;