import React, { useState, useEffect, useRef } from 'react';
import { Platform, View, StyleSheet } from 'react-native';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as Notifications from 'expo-notifications';
import { auth, authService } from './firebase';
import TabBar from './components/TabBar';
import ScreenWrapper from './components/ScreenWrapper';

// Import des écrans
import HomeScreen from './screens/HomeScreen';
import ReservationScreen from './screens/ReservationScreen';
import AuthScreen from './screens/AuthScreen';
import FideliteScreen from './screens/FideliteScreen';
import AdminScreen from './screens/admin/AdminScreen';

import { initializeApp, getApps } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

let app;
if (!getApps().length) {
  app = initializeApp({
    apiKey: "AIzaSyA-eRHbO5WbegU7-W1AmlMdMQ_udfQG-sw",
    projectId: "lmplanner-429000",
    messagingSenderId: "552996729506",
    appId: "1:552996729506:web:daf1ed24220126c0531dfb"
  });
} else {
  app = getApps()[0];
}

const messaging = getMessaging(app);

const Tab = createBottomTabNavigator();


const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigationRef = useRef(null);
  
  useEffect(() => {
    if (Platform.OS === 'web' && 'serviceWorker' in navigator) {
      // D'abord vérifier si on peut accéder au SW
      console.log('📍 Vérification du SW - Origin:', window.location.origin);
      
      fetch('/firebase-messaging-sw.js')
        .then(response => {
          console.log('✅ SW trouvé:', response.ok, 'Status:', response.status);
          if (!response.ok) {
            throw new Error(`SW non accessible - Status: ${response.status}`);
          }
          // Si le SW est accessible, on procède à l'enregistrement
          return navigator.serviceWorker
            .register('/firebase-messaging-sw.js')
            .then((registration) => {
              console.log('✅ Service Worker enregistré avec succès:', registration);
            });
        })
        .catch((error) => {
          console.error('❌ Erreur avec le SW:', error.message);
          // On peut aussi vérifier avec un chemin relatif
          console.log('🔄 Tentative avec chemin relatif...');
          fetch('./firebase-messaging-sw.js')
            .then(response => {
              console.log('✅ SW trouvé (chemin relatif):', response.ok);
            })
            .catch(err => {
              console.error('❌ SW non trouvé (chemin relatif):', err);
            });
        });
    }
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web' && currentUser && 'Notification' in window) {
      // On vérifie toujours automatiquement, mais on ne demande plus directement
      const checkNotificationPermission = async () => {
        const permission = await Notification.permission;
        if (permission === 'default') {
          // On ne demande plus automatiquement ici
          // La demande se fera via le bouton dans UserDashboard
          localStorage.setItem('notificationPermissionAsked', 'true');
        }
      };
      checkNotificationPermission();
    }
  }, [currentUser]);

  useEffect(() => {
    onMessage(messaging, async (payload) => {
      console.log("🔔 Notification reçue dans l'app :", JSON.stringify(payload, null, 2));
    
      // Vérifier si on a une notification native
      if (payload.notification && Platform.OS === 'web') {
        // Créer une notification native Web
        if ('Notification' in window && Notification.permission === 'granted') {
          try {
            new Notification(payload.notification.title, {
              body: payload.notification.body,
              icon: '/icon.png' // Assurez-vous d'avoir une icône
            });
          } catch (error) {
            console.error("Erreur lors de l'affichage de la notification native:", error);
          }
        }
        return;
      }
    
      // Pour les notifications non-natives ou sur mobile
      const { title, body, clickAction } = payload.data || {};
    
      try {
        await Notifications.scheduleNotificationAsync({
          content: {
            title: title || payload.notification?.title,
            body: body || payload.notification?.body,
            data: { action: clickAction }
          },
          trigger: null
        });
      } catch (error) {
        console.error("Erreur lors de l'affichage de la notification:", error);
      }
    });
  }, []);

  // Ajouter un gestionnaire de clic sur les notifications
  useEffect(() => {
    const subscription = Notifications.addNotificationResponseReceivedListener(response => {
      const action = response.notification.request.content.data?.action;
      if (action && navigationRef.current) {
        navigationRef.current.navigate(action);
      }
    });

    return () => subscription.remove();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const response = await authService.getUserData(user.uid);
        setCurrentUser(user);
        const userTypeValue = response?.data?.userType;
        setUserType(userTypeValue);
      } else {
        setCurrentUser(null);
        setUserType(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web' && currentUser) {
      navigator.serviceWorker.ready
        .then((registration) => {
          console.log("✅ Service Worker prêt :", registration);
  
          return getToken(messaging, {
            vapidKey: "BDvpb3CsEERz5Fm2nJwXsoqxrwBQFKJT__05Sjq7gvHR7EyEIF_HUYFjuj5Q3EbZx5xD55qLTho1W224kEVFhI4",
            serviceWorkerRegistration: registration
          });
        })
        .then((token) => {
          if (token) {
            console.log("🎯 Token FCM récupéré :", token);
            
            return fetch("https://13-39-76-140.nip.io/api/notifications/token", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
              },
              body: JSON.stringify({
                userId: currentUser?.uid || "",  // Évite undefined/null
                token: token || ""  // Évite undefined/null
              })
            })
            .then(response => {
              if (!response.ok) {
                throw new Error(`Erreur HTTP ${response.status}`);
              }
              return response.json();
            })
            .then(data => {
              console.log("🔍 Réponse du backend :", data);
            })
            .catch(error => {
              console.error("❌ Erreur lors de l'envoi du token FCM :", error);
            });
          }
        })
        .catch(error => {
          console.error("Erreur lors de la récupération du token :", error);
        });
    }
  }, [currentUser]);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log("Message reçu en premier plan :", payload);
    });
  }, []);

  useEffect(() => {
    if (!isLoading && userType === 'provider' && navigationRef.current) {
      setTimeout(() => {
        navigationRef.current?.navigate('Admin');
      }, 100);
    }
  }, [isLoading, userType]);

  const HomeScreenWrapper = () => (
    <ScreenWrapper currentUser={currentUser}>
      <HomeScreen />
    </ScreenWrapper>
  );

  const ReservationScreenWrapper = () => (
    <ScreenWrapper currentUser={currentUser}>
      <ReservationScreen />
    </ScreenWrapper>
  );

  const FideliteScreenWrapper = () => (
    <ScreenWrapper currentUser={currentUser}>
      <FideliteScreen />
    </ScreenWrapper>
  );

  const AuthScreenWrapper = () => (
    <ScreenWrapper currentUser={currentUser}>
      <AuthScreen />
    </ScreenWrapper>
  );

  const AdminScreenWrapper = () => (
    <ScreenWrapper currentUser={currentUser}>
      <AdminScreen />
    </ScreenWrapper>
  );

  const screens = [
    <Tab.Screen 
      key="accueil" 
      name="Accueil" 
      component={HomeScreenWrapper}
      options={{ 
        headerShown: false,
        title: 'LmPlanner'
      }}
    />, 
    <Tab.Screen 
      key="reservation" 
      name="Réservation" 
      component={ReservationScreenWrapper}
      options={{ 
        headerShown: false,
        title: 'LmPlanner'
      }}
    />, 
    <Tab.Screen 
      key="fidelite" 
      name="Fidélité" 
      component={FideliteScreenWrapper}
      options={{ 
        headerShown: false,
        title: 'LmPlanner'
      }}
    />, 
    <Tab.Screen 
      key="compte" 
      name="Compte" 
      component={AuthScreenWrapper}
      options={{ 
        headerShown: false,
        title: 'LmPlanner'
      }}
    />
  ];

  if (userType === 'provider') {
    screens.unshift(
      <Tab.Screen 
        key="admin"
        name="Admin" 
        component={AdminScreenWrapper}
        options={{ 
          headerShown: false,
          title: 'LmPlanner'
        }}
      />
    );
  }

  if (isLoading) {
    return null;
  }

  return (
    <View style={[
      StyleSheet.absoluteFill,
      Platform.OS === 'web' && {
        position: 'fixed',
        overflow: 'hidden',
      }
    ]}>
      <NavigationContainer ref={navigationRef}>
        <Tab.Navigator
          key={userType}
          tabBar={props => <TabBar {...props} currentUser={{ ...currentUser, type: userType }} />}
          screenOptions={{
            headerShown: false,
          }}
        >
          {screens}
        </Tab.Navigator>
      </NavigationContainer>
    </View>
  );
};

export default App;
