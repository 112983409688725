import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Modal, ScrollView, ActivityIndicator, TouchableOpacity, Animated, Easing } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { colors } from '../screenStyles/GlobalStyles';
import styles from '../componentStyles/ConfirmationModalStyles';
import { SERVICES, SUPPLEMENTS, REWARDS } from '../constants/services';
import Notification from './Notification';
import { pointsService } from '../services/pointsService';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';


const additionalStyles = {
  vipBadge: {
    backgroundColor: colors.primary,
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 12,
    marginLeft: 8,
  },
  vipBadgeText: {
    color: '#fff',
    fontSize: 12,
    fontWeight: '600',
    textAlign: 'center',
  },
  vipBonus: {
    color: colors.primary,
    fontStyle: 'italic',
    fontSize: 13,
  },
  vipInfoContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    padding: 12,
    borderRadius: 8,
    marginTop: 12,
    alignItems: 'center',
  },
  vipInfoText: {
    color: colors.primary,
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
  },
};

// Composant Dropdown réutilisable
const Dropdown = ({ title, expanded, onPress, icon, children, activeSection }) => {
  const [animation] = useState(new Animated.Value(0));
  const [contentHeight, setContentHeight] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (expanded) {
      contentRef.current?.measure((x, y, width, height) => {
        setContentHeight(height);
      });
    }
    setIsAnimating(true);
    Animated.timing(animation, {
      toValue: expanded ? 1 : 0,
      duration: 500,
      useNativeDriver: false,
      easing: Easing.inOut(Easing.ease),
    }).start(() => {
      setIsAnimating(false);
    });
  }, [expanded]);

  const rotateIcon = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg']
  });

  const contentStyle = {
    overflow: 'hidden',
    height: animation.interpolate({
      inputRange: [0, 1],
      outputRange: [0, contentHeight]
    })
  };

  const headerBackgroundColor = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ['#fff', colors.primary]
  });

  const headerTextColor = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ['#000', '#fff']
  });

  return (
    <View>
      <TouchableOpacity onPress={onPress} activeOpacity={0.7}>
        <Animated.View style={[
          styles.dropdownHeader,
          {
            backgroundColor: headerBackgroundColor,
          },
          expanded && styles.dropdownHeaderActive,
        ]}>
          <View style={styles.dropdownTitleContainer}>
            <Animated.View>
              <Ionicons
                name={icon}
                size={24}
                color={expanded || isAnimating ? '#fff' : '#000'}
              />
            </Animated.View>
            <Animated.Text style={[
              styles.dropdownTitle,
              {
                color: headerTextColor,
              },
              expanded && styles.dropdownTitleActive,
            ]}>{title}</Animated.Text>
            <Animated.View>
              <Ionicons
                name={icon}
                size={24}
                color={expanded || isAnimating ? '#fff' : '#000'}
              />
            </Animated.View>
          </View>
          <Animated.View style={{ transform: [{ rotate: rotateIcon }] }}>
            <Ionicons 
              name="chevron-down" 
              size={24} 
              color={expanded || isAnimating ? '#fff' : '#000'} 
            />
          </Animated.View>
        </Animated.View>
      </TouchableOpacity>
      
      <Animated.View style={contentStyle}>
        <View 
          ref={contentRef}
          style={styles.dropdownContent}
          onLayout={(event) => {
            const { height } = event.nativeEvent.layout;
            setContentHeight(height);
          }}
        >
          {children}
        </View>
      </Animated.View>
    </View>
  );
};

const ConfirmationModal = ({
  visible,
  slot,
  user,
  onConfirm,
  onClose,
  isLoading: isLoadingProp
}) => {
  const [selectedService, setSelectedService] = useState(null);
  const [selectedSupplements, setSelectedSupplements] = useState(['no_supplement']);
  const [selectedReward, setSelectedReward] = useState(null);
  const [expandedSection, setExpandedSection] = useState('prestations');
  const [finalPrice, setFinalPrice] = useState(0);
  const [pointsToEarn, setPointsToEarn] = useState(0);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isInitialOpen, setIsInitialOpen] = useState(true);
  const [userPoints, setUserPoints] = useState(0);
  const [progressValue] = useState(new Animated.Value(0));
  const [progressStep, setProgressStep] = useState(0);
  const progressSteps = [
    'Vérification des disponibilités...',
    'Préparation de la réservation...',
    'Confirmation en cours...'
  ];

  useEffect(() => {
    if (visible) {
      if (isInitialOpen) {
        setTimeout(() => {
          setExpandedSection('prestations');
          setIsInitialOpen(false);
        }, 750);
      }
    } else {
      setExpandedSection('');
      setIsInitialOpen(true);
      setSelectedService(null);
      setSelectedSupplements(['no_supplement']);
      setSelectedReward(null);
      setFinalPrice(0);
      setPointsToEarn(0);
    }
  }, [visible]);

  useEffect(() => {
    if (visible && user) {
      // Récupérer les points directement depuis l'objet user
      const points = parseInt(user.loyaltyPoints) || 0;
      setUserPoints(points);
      console.log('Points loaded from user object:', points);
    }
  }, [visible, user]);

  useEffect(() => {
    if (user?.loyaltyPoints) {
      const points = parseInt(user.loyaltyPoints);
      setUserPoints(points || 0);
    }
  }, [user?.loyaltyPoints]);

  useEffect(() => {
    if (slot?.service) {
      setSelectedService(slot.service);
    }
  }, [slot]);

  useEffect(() => {
    const points = calculatePoints();
    setPointsToEarn(points);
  }, [selectedService, selectedSupplements, selectedReward]);

  useEffect(() => {
    if (selectedReward) {
      let isValid = false;

      switch (selectedReward.id) {
        case 'free-cut':
        case 'half-off':
          isValid = ['coupe', 'coupe-barbe'].includes(selectedService?.id);
          break;
        case 'free-design':
          isValid = selectedSupplements.includes('design');
          break;
        case 'free-enhancement':
          isValid = selectedSupplements.includes('enhancement-pro');
          break;
        case 'free-cut-beard':
          isValid = selectedService?.id === 'coupe-barbe';
          break;
        default:
          isValid = false;
      }

      // Si la réduction n'est plus valide, on la retire
      if (!isValid) {
        console.log('Removing invalid reward', selectedReward.id);
        setSelectedReward(null);
      }
    }
  }, [selectedService, selectedSupplements, selectedReward]);

  useEffect(() => {
    if (selectedService) {
      const servicePrice = selectedService.price || 0;
      const supplementsTotal = selectedSupplements.reduce((total, id) => {
        const supplement = getSupplementById(id);
        return total + (supplement ? supplement.price : 0);
      }, 0);
      const discount = selectedReward ? selectedReward.discount : 0;
      
      const calculatedFinalPrice = servicePrice + supplementsTotal - discount;
      setFinalPrice(calculatedFinalPrice);
    }
  }, [selectedService, selectedSupplements, selectedReward]);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
    setTimeout(() => {
      setExpandedSection('supplements');
    }, 500);
  };

  const handleSupplementSelect = (supplement) => {
    if (!selectedService) {
      return; 
    }

    if (supplement.id === 'no_supplement') {
      setSelectedSupplements(['no_supplement']);
      setTimeout(() => {
        setExpandedSection('reductions');
      }, 500);
    } else {
      const newSupplements = [...selectedSupplements].filter(id => id !== 'no_supplement');
      
      const isSelected = newSupplements.includes(supplement.id);
      if (isSelected) {
        const index = newSupplements.indexOf(supplement.id);
        newSupplements.splice(index, 1);
      } else {
        if (supplement.type === 'enhancement' && selectedService.id === 'contours') {
          return; 
        }
        if (supplement.type === 'design' && selectedService.id === 'contours') {
          return; 
        }
        newSupplements.push(supplement.id);
      }

      if (newSupplements.length === 0) {
        setSelectedSupplements(['no_supplement']);
      } else {
        setSelectedSupplements(newSupplements);
      }
    }
  };

  useEffect(() => {
    if (selectedService) {
      setExpandedSection('supplements');
    }
  }, [selectedService]);

const handleRewardSelect = (reward) => {
  if (!reward) {
    setSelectedReward(null);
    setTimeout(() => {
      setExpandedSection('');
    }, 500);
    return;
  }

  // Vérification directe avec console.log pour debug
  console.log('Selected supplements:', selectedSupplements);
  console.log('Reward:', reward);
  
  let isValid = false;
  
  switch (reward.id) {
    case 'free-cut':
    case 'half-off':
      isValid = ['coupe', 'coupe-barbe'].includes(selectedService?.id);
      break;
    case 'free-cut-beard':
      isValid = selectedService?.id === 'coupe-barbe';
      break;
    case 'free-design':
      isValid = selectedSupplements.includes('design');
      break;
    case 'free-enhancement':
      // Vérification plus simple et directe
      isValid = selectedSupplements.includes('enhancement-pro');
      console.log('Enhancement validation:', isValid);
      break;
    default:
      isValid = false;
  }

  if (isValid) {
    console.log('Setting reward:', reward);
    setSelectedReward(reward);
    setTimeout(() => {
      setExpandedSection('');
    }, 500);
  } else {
    console.warn('Réduction non applicable à cette prestation');
  }
};

  const formatDate = (date) => {
    if (!date) return '';
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) return '';
    return dateObj.toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  const animateProgressToValue = (toValue, duration) => {
    return new Promise((resolve) => {
      Animated.timing(progressValue, {
        toValue,
        duration,
        useNativeDriver: false,
        easing: Easing.inOut(Easing.ease),
      }).start(({ finished }) => {
        if (finished) resolve();
      });
    });
  };

  const handleConfirm = async () => {
    if (!user) {
        onClose();
        setShowNotification(true);
        return;
    }

    if (!selectedService) {
        onClose();
        setShowNotification(true);
        return;
    }

    setIsLoadingLocal(true);
    progressValue.setValue(0);
    setProgressStep(0);

    try {
        // On saute directement à la préparation de la réservation
        setProgressStep(1);
        await animateProgressToValue(0.5, 500);

        const points = calculatePoints();
        const bookingData = {
            service: selectedService,
            finalPrice,
            pointsToEarn: points.toEarn,
            supplements: selectedSupplements
                .filter(id => id !== 'no_supplement')
                .map(id => {
                    const supplement = getSupplementById(id);
                    return supplement ? {
                        id: supplement.id,
                        name: supplement.name,
                        price: supplement.price,
                        points: supplement.points
                    } : null;
                })
                .filter(Boolean),
            reward: selectedReward,
            date: slot.date,
            time: slot.time,
            pointsProcessed: false,
            status: 'scheduled'
        };

        await pointsService.processBookingPoints(user.email, bookingData);

        // Confirmation finale
        setProgressStep(2);
        await animateProgressToValue(0.75, 400);
        await onConfirm(bookingData);
        await animateProgressToValue(1, 300);

    } catch (error) {
        console.error('Error in confirmation:', error);
    } finally {
        setIsLoadingLocal(false);
        onClose();
        setTimeout(() => {
            setShowNotification(true);
        }, 300);
    }
};

  const isLoading = isLoadingProp || isLoadingLocal;

  const getSupplementById = (id) => {
    const supplementKey = Object.keys(SUPPLEMENTS).find(key => {
      const supplement = SUPPLEMENTS[key];
      return supplement.id === id;
    });
    return supplementKey ? SUPPLEMENTS[supplementKey] : null;
  };

  const isItemAffectedByReward = (itemId, reward) => {
    if (!reward) return false;
  
    switch (reward.id) {
      case 'free-cut':
      case 'half-off':
        return ['coupe', 'coupe-barbe'].includes(itemId); // Ajout de coupe-barbe ici
      case 'free-cut-beard':
        return itemId === 'coupe-barbe';
      case 'free-design':
        return itemId === 'design';
      case 'free-enhancement':
        return ['enhancement-basic', 'enhancement-pro'].includes(itemId);
      default:
        return false;
    }
  };
  

const isRewardAvailable = (reward) => {
  if (!selectedService) return false;

  switch (reward.id) {
    case 'free-cut':
    case 'half-off':
      return ['coupe', 'coupe-barbe'].includes(selectedService.id);
    case 'free-cut-beard':
      return selectedService.id === 'coupe-barbe';
    case 'free-design':
      return selectedSupplements.includes('design');
    case 'free-enhancement':
      return selectedSupplements.includes('enhancement-pro');
    default:
      return false;
  }
};

  const calculatePoints = () => {
    // Points à gagner (en attente de complétion)
    const futurePoints = selectedService && !isItemAffectedByReward(selectedService?.id, selectedReward)
        ? (user?.isVIP ? selectedService.points * 2 : selectedService.points)
        : 0;

    // Points des suppléments (en attente)
    const futureSuppPoints = selectedSupplements
        .filter(id => id !== 'no_supplement')
        .reduce((sum, id) => {
            const supplement = getSupplementById(id);
            if (supplement && !isItemAffectedByReward(supplement.id, selectedReward)) {
                const basePoints = supplement.points;
                return sum + (user?.isVIP ? basePoints * 2 : basePoints);
            }
            return sum;
        }, 0);

    // Points de réduction (immédiats)
    const reductionPoints = selectedReward ? selectedReward.points : 0;

    return {
        toDeduct: reductionPoints,  // Points à déduire immédiatement
        toEarn: futurePoints + futureSuppPoints,  // Points à gagner après completion
        projectedBalance: userPoints - reductionPoints // Solde après déduction
    };
  };

// Dans le résumé, pour afficher le total final :
const getFinalPointsTotal = () => {
    return userPoints + calculatePoints();
};
  
  

  const calculateTotal = () => {
    const servicePrice = selectedService ? selectedService.price : 0;
    const supplementsPrice = selectedSupplements
      .filter(id => id !== 'no_supplement')
      .reduce((sum, id) => {
        const supplement = getSupplementById(id);
        return sum + (supplement ? supplement.price : 0);
      }, 0);
    const discount = selectedReward ? selectedReward.discount : 0;
    return servicePrice + supplementsPrice - discount;
  };


  const renderSummary = () => {
    const isVIP = user?.isVIP;
    const points = calculatePoints();

    return (
      <View style={styles.summaryContainer}>
        <View style={styles.summaryTitleContainer}>
          <Text style={styles.summaryTitle}>Résumé de la réservation</Text>
          {isVIP && (
            <View style={additionalStyles.vipBadge}>
              <Text style={additionalStyles.vipBadgeText}>👑 VIP 👑</Text>
            </View>
          )}
        </View>

        <View style={styles.summaryCard}>
          {/* Service principal */}
          <View style={styles.summaryRow}>
            <Text style={styles.summaryText}>
              - {selectedService?.name || 'Service'}
              {isVIP && !isItemAffectedByReward(selectedService?.id, selectedReward) && (
                <Text style={additionalStyles.vipBonus}> (Points x2)</Text>
              )}
            </Text>
            <View style={styles.pricePointsContainer}>
              <Text style={styles.summaryPrice}>{selectedService?.price || 0}€</Text>
              <Text style={[
                styles.pointsText,
                styles.pendingPoints
              ]}>
                +{isItemAffectedByReward(selectedService?.id, selectedReward) ? 0 : 
                  (isVIP ? selectedService?.points * 2 : selectedService?.points)} ⭐️*
              </Text>
            </View>
          </View>

          {/* Suppléments */}
          {selectedSupplements
            .filter(id => id !== 'no_supplement')
            .map(suppId => {
              const supplement = getSupplementById(suppId);
              const supplementPoints = isItemAffectedByReward(supplement.id, selectedReward) ? 0 : 
                (isVIP ? supplement.points * 2 : supplement.points);
              
              return (
                <View key={suppId} style={styles.summaryRow}>
                  <Text style={styles.summaryText}>
                    - {supplement.name}
                    {isVIP && !isItemAffectedByReward(supplement.id, selectedReward) && (
                      <Text style={additionalStyles.vipBonus}> (Points x2)</Text>
                    )}
                  </Text>
                  <View style={styles.pricePointsContainer}>
                    <Text style={styles.summaryPrice}>+{supplement.price}€</Text>
                    <Text style={[
                      styles.pointsText,
                      styles.pendingPoints
                    ]}>
                      +{supplementPoints} ⭐️*
                    </Text>
                  </View>
                </View>
              );
            })}

          {/* Réduction */}
          {selectedReward && (
            <View style={styles.summaryRow}>
              <Text style={styles.summaryText}>
                - Réduction : {selectedReward.name}
              </Text>
              <View style={styles.pricePointsContainer}>
                <Text style={[styles.summaryPrice, styles.reductionText]}>
                  -{selectedReward.discount}€
                </Text>
                <Text style={[styles.pointsText, styles.pointsNegative]}>
                  -{selectedReward.points} ⭐️
                </Text>
              </View>
            </View>
          )}

          {/* Points Info */}
          <View style={styles.pointsInfoContainer}>
            <Text style={styles.currentPoints}>Points actuels : {userPoints} ⭐️</Text>
            
            {selectedReward && (
                <>
                    <Text style={styles.pointsDeduction}>
                        Points déduits : -{points.toDeduct} ⭐️
                    </Text>
                    <Text style={styles.projectedPoints}>
                        Nouveau solde : {points.projectedBalance} ⭐️
                    </Text>
                </>
            )}

            {points.toEarn > 0 && (
                <View style={styles.futurePointsContainer}>
                    <Text style={styles.futurePointsText}>
                        Points à gagner après RDV effectué et payé : 
                        +{points.toEarn} ⭐️
                    </Text>
                </View>
            )}
        </View>

          {/* Total */}
          <View style={styles.totalContainer}>
            <Text style={styles.totalText}>Total</Text>
            <View style={styles.pricePointsContainer}>
              <Text style={styles.totalPrice}>{calculateTotal()}€</Text>
            </View>
          </View>
        </View>
      </View>
    );
  };
  
  
  

  if (!visible) return null;

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="fade"
      onRequestClose={onClose}
      statusBarTranslucent={true}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.modalContent}>
          {/* Header */}
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>Réservation</Text>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={onClose}
            >
              <Ionicons name="close" size={28} color="#666" />
            </TouchableOpacity>
          </View>

          {/* Date et Heure - Maintenant en dehors du ScrollView */}
          {slot && (
            <View style={styles.dateTimeContainer}>
              <View style={styles.dateTimeRow}>
                <Ionicons name="calendar" size={20} style={styles.dateTimeIcon} />
                <Text style={styles.dateTimeLabel}>Date :</Text>
                <Text style={styles.dateTimeValue}>{formatDate(slot.date)}</Text>
              </View>

              <View style={styles.dateTimeRow}>
                <Ionicons name="time" size={20} style={styles.dateTimeIcon} />
                <Text style={styles.dateTimeLabel}>Heure :</Text>
                <Text style={styles.dateTimeValue}>{slot.time}</Text>
              </View>
            </View>
          )}

          <ScrollView 
            style={styles.modalBody}
            contentContainerStyle={styles.modalBodyContent}
            showsVerticalScrollIndicator={false}
            bounces={false}
          >
            {/* Prestations */}
            <View style={[
              styles.dropdownContainer,
              expandedSection === 'prestations' && { borderWidth: 0, shadowOpacity: 0, elevation: 0 }
            ]}>
              <Dropdown 
                title="Prestations" 
                expanded={expandedSection === 'prestations'}
                onPress={() => setExpandedSection(expandedSection === 'prestations' ? '' : 'prestations')}
                icon="cut"
                activeSection={expandedSection}
              >
                {Object.values(SERVICES).map(service => (
                  <TouchableOpacity
                    key={service.id}
                    style={[
                      styles.serviceOption,
                      selectedService?.id === service.id && styles.rewardOptionSelected
                    ]}
                    onPress={() => handleServiceSelect(service)}
                  >
                    <View style={styles.serviceOptionContent}>
                      <View style={[
                        styles.decorativeIcon,
                        selectedService?.id === service.id && styles.decorativeIconSelected
                      ]}>
                        <Ionicons name="cut-outline" size={16} color={selectedService?.id === service.id ? '#1a73e8' : '#000'} />
                      </View>
                      <View style={styles.serviceInfo}>
                        <View style={styles.serviceNameContainer}>
                          <Text style={[
                            styles.serviceName,
                            selectedService?.id === service.id && styles.dropdownItemTextSelected
                          ]}>
                            ✂️ {service.name} ✂️
                          </Text>
                          <Text style={[
                            styles.servicePoints,
                            selectedService?.id === service.id && styles.greenTextSelected
                          ]}>
                            +{service.points} points ⭐️
                          </Text>
                        </View>
                      </View>
                      <Text style={styles.servicePrice}>{service.price}€</Text>
                    </View>
                  </TouchableOpacity>
                ))}
              </Dropdown>
            </View>

            {/* Suppléments */}
            <View style={[
              styles.dropdownContainer,
              expandedSection === 'supplements' && { borderWidth: 0, shadowOpacity: 0, elevation: 0 }
            ]}>
              <Dropdown 
                title="Suppléments" 
                expanded={expandedSection === 'supplements'}
                onPress={() => setExpandedSection(expandedSection === 'supplements' ? '' : 'supplements')}
                icon="add-circle"
                activeSection={expandedSection}
              >
                <TouchableOpacity
                  style={[
                    styles.supplementOption,
                    selectedSupplements.includes('no_supplement') && styles.noSupplementSelected
                  ]}
                  onPress={() => handleSupplementSelect(SUPPLEMENTS.NO_SUPPLEMENT)}
                >
                  <View style={styles.noSupplementContainer}>
                    <Text style={[
                      styles.noSupplementText,
                      selectedSupplements.includes('no_supplement') && styles.noSupplementTextSelected
                    ]}>
                      ❌ Aucun supplément ❌
                    </Text>
                    <Text style={styles.savePointsText}>
                      Prestation simple et efficace
                    </Text>
                  </View>
                </TouchableOpacity>

                {Object.values(SUPPLEMENTS)
                  .filter(supplement => supplement.id !== 'no_supplement')
                  .map(supplement => {
                    const isSelected = selectedSupplements.includes(supplement.id);
                    const getSupplementIcon = (type) => {
                      switch (type) {
                        case 'enhancement':
                          return '✨';
                        case 'design':
                          return '🎨';
                        default:
                          return '⭐️';
                      }
                    };
                    return (
                      <TouchableOpacity
                        key={supplement.id}
                        style={[
                          styles.supplementOption,
                          isSelected && styles.rewardOptionSelected,
                          !selectedService && styles.supplementOptionDisabled
                        ]}
                        onPress={() => handleSupplementSelect(supplement)}
                        disabled={!selectedService}
                      >
                        <View style={styles.supplementOptionContent}>
                          <View style={[
                            styles.checkbox,
                            isSelected && styles.checkboxChecked
                          ]}>
                            {isSelected && (
                              <Ionicons name="checkmark" size={18} color="#fff" />
                            )}
                          </View>
                          <View style={styles.supplementInfo}>
                            <View style={styles.supplementNameContainer}>
                              <Text style={[
                                styles.supplementName,
                                isSelected && styles.dropdownItemTextSelected,
                                !selectedService && styles.supplementNameDisabled
                              ]}>
                                {getSupplementIcon(supplement.type)} {supplement.name} {getSupplementIcon(supplement.type)}
                              </Text>
                              <Text style={[
                                styles.supplementPoints,
                                isSelected && styles.greenTextSelected,
                                !selectedService && styles.supplementPointsDisabled
                              ]}>
                                +{supplement.points} points ⭐️
                              </Text>
                            </View>
                          </View>
                          <Text style={[
                            styles.supplementPrice,
                            !selectedService && styles.supplementPriceDisabled
                          ]}>{supplement.price}€</Text>
                        </View>
                      </TouchableOpacity>
                    );
                  })}
              </Dropdown>
            </View>

            {/* Réductions */}
            <View style={[
  styles.dropdownContainer,
  expandedSection === 'reductions' && { borderWidth: 0, shadowOpacity: 0, elevation: 0 }
]}>
  <Dropdown 
    title="Réductions" 
    expanded={expandedSection === 'reductions'}
    onPress={() => setExpandedSection(expandedSection === 'reductions' ? '' : 'reductions')}
    icon="pricetag"
    activeSection={expandedSection}
  >
    {/* Option Aucune Réduction */}
    <TouchableOpacity
      style={[
        styles.rewardOption,
        !selectedReward && styles.rewardOptionSelected
      ]}
      onPress={() => handleRewardSelect(null)}
    >
      <View style={styles.noRewardContainer}>
        <Text style={[
          styles.noRewardText,
          !selectedReward && styles.dropdownItemTextSelected
        ]}>
          💰 Aucune réduction 💰
        </Text>
        <Text style={styles.savePointsText}>
          Garder mes points
        </Text>
      </View>
    </TouchableOpacity>

    {/* Options de Réduction dynamiques */}
    {[
      {
        id: 'half-off',
        name: '-50% sur une coupe',
        points: 125,
        discount: 5,
        description: '-50% sur une coupe',
      },
      {
        id: 'free-cut',
        name: 'Coupe gratuite',
        points: user?.isVIP ? 0 : 250,
        discount: 10,
        description: 'Coupe gratuite',
      },
      {
        id: 'free-cut-beard',  // Ajout de cette réduction
        name: 'Coupe + Barbe gratuite',
        points: 375,
        discount: 15,
        description: 'Une coupe et une barbe gratuites',
      },
      {
        id: 'free-design',
        name: 'Design simple gratuit',
        points: 50,
        discount: 2,
        description: 'Design simple',
      },
      {
        id: 'free-enhancement',
        name: 'Fibre noir avec fixateur gratuit',
        points: 150,
        discount: 5,
        description: 'Enhancement avec fixateur',
      },
    ]
      .filter(reward => isRewardAvailable(reward) && userPoints >= reward.points) // Vérifie éligibilité et points disponibles
      .map((reward) => (
        <TouchableOpacity
          key={reward.id}
          style={[
            styles.rewardOption,
            selectedReward?.id === reward.id && styles.rewardOptionSelected
          ]}
          onPress={() => handleRewardSelect(reward)}
        >
          <View style={styles.serviceOptionContent}>
            <View style={styles.decorativeIcon}>
              <Ionicons name="gift-outline" size={16} color={selectedReward?.id === reward.id ? '#1a73e8' : '#000'} />
            </View>
            <View style={styles.serviceInfo}>
              <View style={styles.serviceNameContainer}>
                <Text style={[
                  styles.serviceName,
                  selectedReward?.id === reward.id && styles.dropdownItemTextSelected
                ]}>
                  {reward.name}
                </Text>
                <Text style={[
                  styles.rewardPoints,
                  selectedReward?.id === reward.id && styles.greenTextSelected
                ]}>
                  -{reward.points} points ⭐️
                </Text>
              </View>
            </View>
            <Text style={[styles.servicePrice, { color: selectedReward?.id === reward.id ? '#2ECC71' : '#000' }]}>
              -{reward.discount}€
            </Text>
          </View>
        </TouchableOpacity>
      ))}
  </Dropdown>
</View>



            {/* Résumé */}
            {selectedService && (
              renderSummary()
            )}
          </ScrollView>

          {isLoading && (
            <View style={styles.progressBarWrapper}>
              <Text style={styles.progressText}>
                {progressSteps[progressStep]}
              </Text>
              <View style={styles.progressBarContainer}>
                <Animated.View 
                  style={[
                    styles.progressBar,
                    {
                      width: progressValue.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0%', '100%']
                      })
                    }
                  ]} 
                />
              </View>
            </View>
          )}

          <View style={styles.buttonsContainer}>
            <TouchableOpacity
              style={[styles.button, styles.cancelButton]}
              onPress={onClose}
            >
              <Text style={styles.cancelButtonText}>Annuler</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.button,
                styles.confirmButton,
                !selectedService && {
                  backgroundColor: '#cccccc', // Gris clair pour indiquer l'état désactivé
                }
              ]}
              onPress={handleConfirm}
              disabled={!selectedService}
            >
              {isLoading ? (
                <ActivityIndicator color="#fff" />
              ) : (
                <Text style={[
                  styles.confirmButtonText,
                  !selectedService && {
                    color: '#666666' // Texte gris foncé pour l'état désactivé
                  }
                ]}>
                  Confirmer
                </Text>
              )}
            </TouchableOpacity>
          </View>

        </View>
      </View>
    </Modal>
  );
};

export default ConfirmationModal;