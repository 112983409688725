import React, { useState, useRef, useEffect } from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator, ScrollView, Animated, Dimensions, Pressable, TextInput } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { colors } from '../../../screenStyles/GlobalStyles';
import styles from './AppointmentManagerStyles';
import { appointmentService } from '../../../services/appointmentService';
import { pointsService } from '../../../services/pointsService';
import { loyaltyService } from '../../../services/loyaltyService';
import { InfoModal, CancelModal, ValidationModal, MediaModal } from './AppointmentModals';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

const getServiceData = (serviceName) => {
  // Table de correspondance des services
  const servicesMap = {
    'Coupe': { id: 'coupe', points: 50, price: 10 },
    'Coupe + Barbe': { id: 'coupe-barbe', points: 75, price: 15 },
    'Contours': { id: 'contours', points: 25, price: 5 },
    'Barbe': { id: 'barbe', points: 35, price: 7 }
  };

  return servicesMap[serviceName] || null;
};

// Configuration de l'API backend
const API_URL = 'https://13-39-76-140.nip.io';

const AppointmentManager = () => {
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [loadingPayments, setLoadingPayments] = useState({});
  const slideAnim = useRef(new Animated.Value(0)).current;
  const [appointments, setAppointments] = useState({
    upcoming: [],
    past: []
  });
  const [dateFilter, setDateFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    loadAppointments();
    return () => subscription?.remove();
  }, []);

  useEffect(() => {
    const unsubscribe = appointmentService.subscribeToAppointments(
      'tous',
      (newAppointments) => {
        setAppointments(newAppointments);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const handleRefresh = async () => {
    if (isRefreshing) return;
    setIsRefreshing(true);
    try {
      await loadAppointments();
    } catch (error) {
      console.error('Erreur lors du rafraîchissement:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const fetchAppointmentDetails = async (appointmentId) => {
    if (!appointmentId) return;
    
    try {
      const appointmentRef = doc(db, 'appointments', appointmentId);
      const appointmentDoc = await getDoc(appointmentRef);
      
      if (appointmentDoc.exists()) {
        const data = appointmentDoc.data();
        // On s'assure que mediaUrls est un tableau
        data.mediaUrls = Array.isArray(data.mediaUrls) ? data.mediaUrls : [];
        console.log('Media URLs:', data.mediaUrls);
        setSelectedAppointment(data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const loadAppointments = async () => {
    try {
      setLoading(true);
      const result = await appointmentService.getAppointments('tous');
      setAppointments(result);
    } catch (error) {
      console.error('Error loading appointments:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentToggle = async (appointment) => {
    try {
      setLoadingPayments(prev => ({ ...prev, [appointment.id]: true }));

      const appointmentRef = doc(db, 'appointments', appointment.id);
      
      // Récupérer les données complètes du service avec les points
      const serviceInfo = getServiceData(appointment.service.name);
      if (!serviceInfo) {
        throw new Error('Service non reconnu');
      }

      // Préparer les données du service avec les points
      const serviceData = {
        ...appointment.service,
        id: serviceInfo.id,
        name: appointment.service.name,
        price: appointment.service.price || serviceInfo.price,
        points: serviceInfo.points // Important: inclure les points du service
      };

      const updatedPaymentStatus = appointment.payment?.status === 'paid' ? '' : 'paid';

      // Mettre à jour le paiement dans Firestore
      await updateDoc(appointmentRef, {
        payment: {
          status: updatedPaymentStatus,
          finalPrice: appointment.bookingDetails?.finalPrice || appointment.finalPrice || serviceData.price,
          updatedAt: new Date()
        },
        service: serviceData // Mettre à jour le service avec les points
      });

      // Traiter les points si le paiement est marqué comme payé
      if (updatedPaymentStatus === 'paid' && appointment.userInfo?.email) {
        await pointsService.processBookingPoints(
          appointment.userInfo.email,
          {
            ...appointment,
            service: serviceData,
            status: appointment.status
          }
        );
      }

    } catch (error) {
      console.error('Erreur lors de la mise à jour du paiement:', error);
    } finally {
      setLoadingPayments(prev => ({ ...prev, [appointment.id]: false }));
    }
  };

 const handleCancelAppointment = async (appointment) => {
  try {
    // Formatage de la date
    const formattedDate = new Date(appointment.dateTime).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    // Construction du nom complet du client
    const customerName = `${appointment.userInfo?.firstName || ''} ${appointment.userInfo?.lastName || ''}`.trim() || "Un client";

    const response = await fetch(`${API_URL}/api/slots/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        appointmentId: appointment.id,
        customerName: customerName,
        time: appointment.time,
        appointmentTime: formattedDate
      })
    });

    const result = await response.json();

    if (result.success) {
      await loadAppointments(); // Recharger la liste des rendez-vous
      setShowCancelModal(false);
    } else {
      throw new Error(result.error || 'Erreur lors de l\'annulation');
    }
  } catch (error) {
    console.error('Error canceling appointment:', error);
  }
 };

  const handleCompleteAppointment = async (appointment) => {
    try {
      const appointmentRef = doc(db, 'appointments', appointment.id);
      
      // Récupérer les données complètes du service avec les points
      const serviceInfo = getServiceData(appointment.service.name);
      if (!serviceInfo) {
        throw new Error('Service non reconnu');
      }

      // Préparer les données du service avec les points
      const serviceData = {
        ...appointment.service,
        id: serviceInfo.id,
        name: appointment.service.name,
        price: appointment.service.price || serviceInfo.price,
        points: serviceInfo.points
      };

      // Mettre à jour le statut
      await updateDoc(appointmentRef, {
        status: 'completed',
        completedAt: new Date(),
        'service.status': 'completed',
        service: serviceData
      });

      // Traiter les points si déjà payé
      if (appointment.payment?.status === 'paid' && appointment.userInfo?.email) {
        await pointsService.processBookingPoints(
          appointment.userInfo.email,
          {
            ...appointment,
            service: serviceData,
            status: 'completed'
          }
        );
      }

      setShowValidationModal(false);
    } catch (error) {
      console.error('Error completing appointment:', error);
    }
  };

  const handleNoShow = async (appointment) => {
    try {
      const appointmentRef = doc(db, 'appointments', appointment.id);
      
      // Mise à jour du statut en cancelled uniquement
      await updateDoc(appointmentRef, {
        status: 'cancelled',
        cancelledAt: new Date(),
        cancellationReason: 'Client non présent'
      });

      // Mettre à jour la liste des rendez-vous
      await loadAppointments();
      setShowValidationModal(false);

    } catch (error) {
      console.error('Error marking as cancelled:', error);
    }
};

  const handleTabPress = (tab) => {
    setActiveTab(tab);
  };

  const filterAppointments = (appointmentsList) => {
    return appointmentsList.filter(appointment => {
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        const nameMatch = appointment.userInfo?.firstName?.toLowerCase().includes(searchLower) 
          || appointment.userInfo?.lastName?.toLowerCase().includes(searchLower);
        const serviceMatch = appointment.service?.name?.toLowerCase().includes(searchLower);
        if (!nameMatch && !serviceMatch) return false;
      }

      if (dateFilter !== 'all') {
        const appointmentDate = new Date(appointment.dateTime);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Réinitialiser l'heure à minuit
        
        switch(dateFilter) {
          case 'today':
            return appointmentDate.toDateString() === today.toDateString();
          case 'week':
            // Obtenir le lundi de la semaine courante
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay() + 1); // Lundi = 1
            startOfWeek.setHours(0, 0, 0, 0);
            
            // Obtenir le dimanche
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6); // +6 jours = dimanche
            endOfWeek.setHours(23, 59, 59, 999);
            
            return appointmentDate >= startOfWeek && appointmentDate <= endOfWeek;
          case 'month':
            return appointmentDate.getMonth() === today.getMonth() && 
                   appointmentDate.getFullYear() === today.getFullYear();
          default:
            return true;
        }
      }
      return true;
    });
  };

  const handleMediaClick = async (appointment) => {
    try {
      await fetchAppointmentDetails(appointment.id);
      setSelectedAppointment(appointment);
      setShowMediaModal(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const renderAppointmentCard = (appointment) => {
    const formattedDate = new Date(appointment.dateTime).toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long'
    });

    const isToday = new Date(appointment.dateTime).toDateString() === new Date().toDateString();
    const isNoShow = appointment.status === 'cancelled' && appointment.cancellationReason === 'Client non présent';

    return (
      <View key={appointment.id} style={[
        styles.appointmentCard,
        isToday && styles.todayAppointment,
        isNoShow && styles.noShowAppointment
      ]}>
        {isToday && (
          <View style={styles.todayBadge}>
            <Text style={styles.todayText}>Aujourd'hui</Text>
          </View>
        )}

        <View style={styles.appointmentHeader}>
          <View>
            <Text style={styles.appointmentDate}>{formattedDate}</Text>
            <Text style={styles.appointmentTime}>{appointment.time}</Text>
            {isNoShow && (
              <View style={styles.noShowBadge}>
                <Ionicons name="close-circle" size={16} color={colors.error} />
                <Text style={styles.noShowText}>Non présent</Text>
              </View>
            )}
          </View>
          <View style={[
            styles.statusBadge,
            { backgroundColor: appointment.payment?.status === 'paid' ? colors.success : colors.primary }
          ]}>
            <Text style={styles.statusText}>
              {appointment.payment?.status === 'paid' ? 'Payé' : 'En attente'}
            </Text>
          </View>
        </View>

        <View style={styles.mainContent}>
          <View style={styles.serviceInfo}>
            <Text style={styles.serviceName}>
              {appointment.service?.name || 'Service inconnu'}
            </Text>
            <Text style={styles.finalPrice}>
              {(appointment.bookingDetails?.finalPrice || appointment.finalPrice || appointment.service?.price)}€
            </Text>
          </View>

          {appointment.bookingDetails?.supplements?.length > 0 && (
            <View style={styles.supplements}>
              {appointment.bookingDetails.supplements.map((supplement, index) => (
                <Text key={index} style={styles.supplementText}>
                  + {supplement.name} ({supplement.price}€)
                </Text>
              ))}
            </View>
          )}

          {appointment.reward && (
            <View style={styles.reward}>
              <Text style={styles.rewardText}>
                Récompense utilisée : {appointment.reward.name}
                {appointment.reward.discount && ` (-${appointment.reward.discount}€)`}
              </Text>
            </View>
          )}

          <View style={styles.clientInfo}>
            <Text style={styles.clientName}>
              {appointment.userInfo?.firstName} {appointment.userInfo?.lastName}
            </Text>
            {appointment.userInfo?.phoneNumber && (
              <Text style={styles.clientPhone}>{appointment.userInfo.phoneNumber}</Text>
            )}
          </View>
        </View>

        <View style={styles.actions}>
          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => {
              setSelectedAppointment(appointment);
              setShowInfoModal(true);
            }}
          >
            <Ionicons name="information-circle" size={24} color={colors.primary} />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => {
              setSelectedAppointment(appointment);
              setShowCancelModal(true);
            }}
          >
            <Ionicons name="close-circle" size={24} color={colors.error} />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => handlePaymentToggle(appointment)}
            disabled={loadingPayments[appointment.id]}
          >
            {loadingPayments[appointment.id] ? (
              <ActivityIndicator size="small" color={colors.primary} />
            ) : (
              <Ionicons 
                name={appointment.payment?.status === 'paid' ? "card" : "card-outline"} 
                size={24} 
                color={appointment.payment?.status === 'paid' ? colors.success : colors.primary} 
              />
            )}
          </TouchableOpacity>

          {activeTab === 'upcoming' ? (
  <TouchableOpacity
    style={styles.actionButton}
    onPress={() => {
      setSelectedAppointment(appointment);
      setShowValidationModal(true);
    }}
  >
    <Ionicons name="checkmark-circle" size={24} color={colors.success} />
  </TouchableOpacity>
) : (
  <TouchableOpacity
  style={styles.actionButton}
  onPress={() => handleMediaClick(appointment)}
>
  <Ionicons 
    name={appointment.hasMedia ? "images" : "cloud-upload-outline"} 
    size={24} 
    color={appointment.hasMedia ? colors.success : colors.primary} 
  />
</TouchableOpacity>
)}
        </View>
      </View>
    );
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.filterContainer}>
        <TouchableOpacity
          style={styles.refreshButton}
          onPress={handleRefresh}
          disabled={isRefreshing}
        >
          {isRefreshing ? (
            <ActivityIndicator size="small" color={colors.primary} />
          ) : (
            <Ionicons 
              name="refresh-outline" 
              size={24} 
              color={colors.primary}
              style={styles.refreshIcon}
            />
          )}
        </TouchableOpacity>

        <TouchableOpacity 
          style={[styles.filterButton, dateFilter === 'all' && styles.activeFilter]}
          onPress={() => setDateFilter('all')}
        >
          <Text style={[
            styles.filterButtonText,
            dateFilter === 'all' && styles.activeFilterText
          ]}>Tous</Text>
        </TouchableOpacity>

        <TouchableOpacity 
          style={[styles.filterButton, dateFilter === 'today' && styles.activeFilter]}
          onPress={() => setDateFilter('today')}
        >
          <Text style={[
            styles.filterButtonText,
            dateFilter === 'today' && styles.activeFilterText
          ]}>Aujourd'hui</Text>
        </TouchableOpacity>

        <TouchableOpacity 
          style={[styles.filterButton, dateFilter === 'week' && styles.activeFilter]}
          onPress={() => setDateFilter('week')}
        >
          <Text style={[
            styles.filterButtonText,
            dateFilter === 'week' && styles.activeFilterText
          ]}>Cette semaine</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.searchContainer}>
        <Ionicons name="search" size={20} color={colors.text} />
        <TextInput
          style={styles.searchInput}
          placeholder="Rechercher un client ou un service..."
          value={searchQuery}
          onChangeText={setSearchQuery}
          placeholderTextColor={colors.placeholder}
        />
      </View>

      <View style={styles.tabContainer}>
        <TouchableOpacity 
          style={[styles.tab, activeTab === 'upcoming' && styles.activeTab]}
          onPress={() => handleTabPress('upcoming')}
        >
          <Ionicons 
            name="calendar-outline"
            size={24} 
            color={activeTab === 'upcoming' ? colors.primary : colors.text} 
          />
          <Text style={[styles.tabText, activeTab === 'upcoming' && styles.activeTabText]}>
            À venir ({filterAppointments(appointments.upcoming).length})
          </Text>
        </TouchableOpacity>

        <TouchableOpacity 
          style={[styles.tab, activeTab === 'past' && styles.activeTab]}
          onPress={() => handleTabPress('past')}
        >
          <Ionicons 
            name="time-outline" 
            size={24} 
            color={activeTab === 'past' ? colors.primary : colors.text}
          />
          <Text style={[styles.tabText, activeTab === 'past' && styles.activeTabText]}>
            Historique ({filterAppointments(appointments.past).length})
          </Text>
        </TouchableOpacity>
      </View>

      <View style={styles.appointmentList}>
        {activeTab === 'upcoming'
          ? filterAppointments(appointments.upcoming).map(appointment => renderAppointmentCard(appointment))
          : filterAppointments(appointments.past).map(appointment => renderAppointmentCard(appointment))
        }
      </View>

      <InfoModal 
        visible={showInfoModal}
        appointment={selectedAppointment}
        onClose={() => {
          setShowInfoModal(false);
          setSelectedAppointment(null);
        }}
      />

      <CancelModal
        visible={showCancelModal}
        appointment={selectedAppointment}
        onClose={() => {
          setShowCancelModal(false);
          setSelectedAppointment(null);
        }}
        onCancel={handleCancelAppointment}
      />

      <ValidationModal
        visible={showValidationModal}
        appointment={selectedAppointment}
        onClose={() => {
          setShowValidationModal(false);
          setSelectedAppointment(null);
        }}
        onComplete={handleCompleteAppointment}
        onNoShow={handleNoShow}
      />

      <MediaModal 
        visible={showMediaModal}
        appointment={selectedAppointment}
        onClose={async () => {
          setShowMediaModal(false);
          setSelectedAppointment(null);
          await loadAppointments(); // Recharger pour voir les nouveaux médias
        }}
      />
    </ScrollView>
  );
};

export default AppointmentManager;