import { StyleSheet, Dimensions, Platform } from 'react-native';
import globalStyles, { colors } from './GlobalStyles';

const { height } = Dimensions.get('window');
const aspectRatio = 9 / 16;

export default StyleSheet.create({
  // Styles de base et conteneurs
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    paddingBottom: Platform.OS === 'ios' ? 100 : 80,
  },
  section: {
    marginBottom: 30,
  },
  welcome: {
    fontSize: 22, 
    fontWeight: '700',
    color: colors.primary,
    marginBottom: 20,
    marginTop: 25,
    textAlign: 'center',
    paddingHorizontal: 20,
    letterSpacing: 0.5,
  },
  sectionTitle: {
    fontSize: 18, 
    fontWeight: '600',
    color: colors.text,
    marginBottom: 16,
    textAlign: 'center',
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 4,
    marginHorizontal: 12,
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    paddingTop: Platform.OS === 'ios' ? 50 : 20,
    paddingBottom: 15,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
    zIndex: 1000,
    elevation: 3,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: colors.text,
    textAlign: 'center',
  },

  // Styles des onglets
  tabContainer: {
    flexDirection: 'row',
    marginBottom: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: 12,
    padding: 8,
    position: 'relative',
    marginHorizontal: 20,
    width: '90%',
    alignSelf: 'center',
    marginTop: -8, // Ajout d'une marge négative pour réduire l'espace
  },
  slider: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    margin: 8,
    backgroundColor: 'white',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
    zIndex: 0,
  },
  tab: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    zIndex: 1,
  },
  tabIcon: {
    marginRight: 8,
  },
  tabText: {
    fontSize: 14,
    fontWeight: '600',
    color: colors.text,
  },
  activeTabText: {
    color: colors.light,
  },
  activeTab: {
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },

  // Styles des rendez-vous
  appointmentList: {
    paddingTop: 0, // Réduit de 5 à 0
    marginTop: -18, // Augmenté de -16 à -18 pour rapprocher encore plus
  },
  appointmentCard: {
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 16,
    marginBottom: 12,
    marginHorizontal: 12,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 8,
    elevation: 4,
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.05)',
  },
  appointmentInfo: {
    flex: 1,
    marginBottom: 10,
  },
  appointmentDate: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 5,
  },
  appointmentTime: {
    fontSize: 14,
    color: colors.text,
    marginBottom: 8,
  },

  // Styles pour état vide
  emptyState: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    marginHorizontal: 10,
  },
  emptyIcon: {
    opacity: 0.5,
    marginBottom: 10,
  },
  emptyText: {
    fontSize: 14,
    color: colors.text,
    textAlign: 'center',
  },

  // Styles pour login
  loginIcon: {
    marginBottom: 20,
  },
  loginTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 12,
  },
  loginMessage: {
    fontSize: 16,
    color: colors.text,
    marginBottom: 24,
    textAlign: 'center',
  },
  buttonContainer: {
    width: '100%',
    paddingHorizontal: 20,
  },
  loginButton: {
    marginVertical: 6,
  },
  buttonText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: '600',
  },

  // Styles des badges et états
  statusBadge: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 20,
    marginTop: 8,
  },
  statusText: {
    fontSize: 13,
    fontWeight: '600',
    color: 'white',
  },

  // Styles de chargement
  refreshIndicator: {
    position: 'absolute',
    top: 10,
    right: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: 20,
    padding: 5,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
  },

  // Styles web spécifiques
  webview: {
    flex: 1,
    width: '100%',
    height: '100%',
    borderRadius: 8,
    overflow: 'hidden',
  },

  // Styles des boutons
  cancelButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFF5F5',
    padding: 8,
    borderRadius: 8,
    alignSelf: 'flex-start',
  },
  cancelButtonText: {
    marginLeft: 4,
    color: colors.error,
    fontSize: 14,
    fontWeight: '600',
  },

  // Style pour la section de paiement
  paymentSection: {
    backgroundColor: colors.accent,
    borderRadius: 12,
    padding: 12,
    marginTop: 8,
  },

  paymentText: {
    color: 'white',
    fontSize: 14,
    fontWeight: '500',
  },

  // Style pour les informations complémentaires
  supplementInfo: {
    backgroundColor: 'rgba(0,0,0,0.03)',
    borderRadius: 10,
    padding: 10,
    marginTop: 8,
  },

  supplementText: {
    fontSize: 13,
    color: colors.textLight,
    fontStyle: 'italic',
  },

  // Nouveaux styles pour les animations de transition
  slideIn: {
    transform: [{ translateY: 0 }],
    opacity: 1,
    transition: 'all 0.3s ease-out',
  },
});