import { initializeApp, getApps } from 'firebase/app';
import { 
  getAuth, 
  signInAnonymously,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  PhoneAuthProvider,
  signInWithCredential,
  RecaptchaVerifier,
  updateProfile,
  updateEmail,
  updatePassword,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  deleteUser
} from 'firebase/auth';
import { 
  getFirestore, 
  doc, 
  setDoc, 
  collection, 
  query, 
  where, 
  getDocs,
  getDoc,
  updateDoc,
  deleteDoc
} from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyA-eRHbO5WbegU7-W1AmlMdMQ_udfQG-sw",
  authDomain: "lmplanner-429000.firebaseapp.com",
  projectId: "lmplanner-429000",
  storageBucket: "lmplanner-429000.appspot.com",
  messagingSenderId: "552996729506",
  appId: "1:552996729506:web:daf1ed24220126c0531dfb",
  measurementId: "G-D0KLJ1YVN0"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];
const auth = getAuth(app);
const db = getFirestore(app);

// Vérifier si le navigateur supporte les fonctionnalités requises
const isSupported = async () => {
  try {
    await getMessaging();
    return true;
  } catch (error) {
    console.log('Firebase Messaging not supported in this browser');
    return false;
  }
};

// Initialiser le messaging de manière conditionnelle
let messaging;
if (typeof window !== 'undefined' && window.navigator) {
  try {
    messaging = getMessaging(app);
  } catch (error) {
    console.log('Firebase Messaging initialization skipped');
  }
}

// Ajouter une vérification avant d'utiliser addEventListener
const setupMessaging = () => {
  if (typeof window !== 'undefined' && messaging) {
    try {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          // ...existing messaging code...
        }
      });
    } catch (error) {
      console.log('Notifications not supported');
    }
  }
};

const getErrorMessage = (error) => {
  switch (error.code) {
    // Erreurs d'authentification email/mot de passe
    case 'auth/invalid-email':
    case 'auth/invalid-credential':
      return 'Email ou mot de passe incorrect';
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return 'Email ou mot de passe incorrect';
    case 'auth/email-already-in-use':
      return 'Cette adresse email est déjà utilisée';
    case 'auth/weak-password':
      return 'Le mot de passe doit contenir au moins 6 caractères';
    case 'auth/requires-recent-login':
      return 'Veuillez vous reconnecter pour effectuer cette action';

    // Erreurs liées au téléphone
    case 'auth/invalid-phone-number':
      return 'Numéro de téléphone invalide';
    case 'auth/missing-phone-number':
      return 'Veuillez entrer un numéro de téléphone';
    case 'auth/quota-exceeded':
      return 'Trop de tentatives, veuillez réessayer plus tard';
    case 'auth/invalid-verification-code':
      return 'Code de vérification invalide';
    case 'auth/code-expired':
      return 'Le code a expiré, veuillez en demander un nouveau';

    // Erreurs de reCAPTCHA
    case 'auth/captcha-check-failed':
      return 'Vérification de sécurité échouée, veuillez réessayer';

    // Erreurs spécifiques à Google Sign-In
    case 'auth/popup-closed-by-user':
      return 'La fenêtre de connexion a été fermée';
    case 'auth/popup-blocked':
      return 'Le popup a été bloqué par votre navigateur';
    case 'auth/cancelled-popup-request':
      return 'La requête de connexion a été annulée';
    case 'auth/account-exists-with-different-credential':
      return 'Un compte existe déjà avec cette adresse email';

    // Erreurs générales
    case 'auth/operation-not-allowed':
      return 'Cette méthode de connexion n\'est pas activée';
    case 'auth/network-request-failed':
      return 'Erreur de connexion réseau. Vérifiez votre connexion internet.';
    case 'auth/too-many-requests':
      return 'Trop de tentatives. Veuillez réessayer plus tard.';
    
    default:
      return 'Une erreur est survenue. Veuillez réessayer.';
  }
};

const authService = {
  // Authentification par email/mot de passe
  loginWithEmail: async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log("Login successful:", userCredential.user.email);
      return { success: true, user: userCredential.user };
    } catch (error) {
      console.error("Login error:", error.code, error.message);
      return { 
        success: false, 
        error: getErrorMessage(error),
        errorCode: error.code 
      };
    }
  },

  // Authentification Google
  signInWithGoogle: async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope('email');
      provider.addScope('profile');
      
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // Extraire prénom et nom
      const names = user.displayName ? user.displayName.split(' ') : ['', ''];
      const firstName = names[0] || '';
      const lastName = names.slice(1).join(' ') || '';
      
      // Vérifier si un compte avec cet email existe déjà
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      
      let userInfo;
      let existingUserId = null;

      if (!querySnapshot.empty) {
        // Un compte avec cet email existe déjà
        const existingUserDoc = querySnapshot.docs[0];
        existingUserId = existingUserDoc.id;
        const existingData = existingUserDoc.data();

        userInfo = {
          ...existingData,
          lastLogin: new Date(),
          // Conserver la méthode d'authentification originale
          authMethod: existingData.authMethod || 'email',
          // Mettre à jour uniquement les infos Google si nécessaire
          photoURL: user.photoURL || existingData.photoURL || null,
        };
      } else {
        // Nouvel utilisateur
        userInfo = {
          firstName,
          lastName,
          email: user.email,
          photoURL: user.photoURL || null,
          authMethod: 'google',
          createdAt: new Date(),
          lastLogin: new Date()
        };

        if (user.phoneNumber) {
          const phoneNumber = user.phoneNumber.replace(/^\+33/, '0');
          userInfo.phoneNumber = phoneNumber;
        }
      }

      // Si un compte existant a été trouvé, utiliser son ID, sinon utiliser l'ID Google
      const userId = existingUserId || user.uid;
      await setDoc(doc(db, "users", userId), userInfo, { merge: true });

      // Vérifier si toutes les infos requises sont présentes
      const hasRequiredInfo = userInfo.firstName && userInfo.lastName && userInfo.phoneNumber;
      
      return { 
        success: true, 
        user: {
          ...user,
          uid: userId // Utiliser l'ID approprié
        },
        isNewUser: !existingUserId && result.additionalUserInfo?.isNewUser,
        requiresCompletion: !hasRequiredInfo
      };
    } catch (error) {
      console.error('Erreur Google Sign-In:', error);
      if (error.code === 'auth/account-exists-with-different-credential') {
        return {
          success: false,
          error: 'Un compte existe déjà avec cette adresse email. Veuillez vous connecter avec votre email et mot de passe.',
          errorCode: error.code
        };
      }
      return {
        success: false,
        error: getErrorMessage(error),
        errorCode: error.code
      };
    }
  },

  registerWithEmail: async (email, password, extraData) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (extraData && extraData.firstName && extraData.lastName) {
        await updateProfile(user, {
          displayName: `${extraData.firstName} ${extraData.lastName}`
        });
      }

      if (extraData) {
        await setDoc(doc(db, "users", user.uid), {
          firstName: extraData.firstName,
          lastName: extraData.lastName,
          phoneNumber: extraData.phoneNumber,
          email: user.email,
          createdAt: new Date(),
        });
      }

      return { success: true, user };
    } catch (error) {
      console.error("Registration error:", error.code, error.message);
      return { 
        success: false, 
        error: getErrorMessage(error),
        errorCode: error.code 
      };
    }
  },

  // Suppression du compte
  deleteAccount: async (uid, password, isGoogleOnly = false) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        return { 
          success: false, 
          error: 'Utilisateur non connecté' 
        };
      }

      // Si c'est un compte Google uniquement, pas besoin de reauth
      if (!isGoogleOnly) {
        // Réauthentification nécessaire pour les comptes email ou liés
        try {
          const credential = EmailAuthProvider.credential(user.email, password);
          await reauthenticateWithCredential(user, credential);
        } catch (error) {
          return {
            success: false,
            error: 'Mot de passe incorrect',
            errorCode: error.code
          };
        }
      }

      // Supprimer le document utilisateur de Firestore
      await deleteDoc(doc(db, "users", uid));
      
      // Supprimer le compte Firebase
      await deleteUser(user);

      return { success: true };
    } catch (error) {
      console.error('Erreur suppression compte:', error);
      return {
        success: false,
        error: 'Erreur lors de la suppression du compte',
        errorCode: error.code
      };
    }
  },
  
  // Authentification anonyme
  signInAnonymously: async () => {
    try {
      const userCredential = await signInAnonymously(auth);
      return { success: true, user: userCredential.user };
    } catch (error) {
      console.error("Anonymous login error:", error);
      return { 
        success: false, 
        error: getErrorMessage(error),
        errorCode: error.code 
      };
    }
  },

  // Vérification et authentification par email
  checkEmailExists: async (email, currentUserUid = null) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', email));
      const snapshot = await getDocs(q);
      
      // Si un currentUserUid est fourni, ignorer cet utilisateur dans la vérification
      const exists = snapshot.docs.some(doc => 
        currentUserUid ? doc.id !== currentUserUid : true
      );
      
      return { success: true, exists };
    } catch (error) {
      console.error('Error checking email:', error);
      return { 
        success: false,
        error: 'Erreur lors de la vérification de l\'email',
        errorCode: error.code
      };
    }
  },

  // Vérification et authentification par téléphone
  checkPhoneExists: async (phoneNumber, currentUserUid) => {
    try {
      // Nettoyer le numéro et créer les formats possibles
      let cleanNumber = phoneNumber.replace(/[^\d]/g, '');
      
      // Si le numéro commence par 33
      if (cleanNumber.startsWith('33')) {
        cleanNumber = cleanNumber.slice(2);
      }
      // Si le numéro commence par 0
      else if (cleanNumber.startsWith('0')) {
        cleanNumber = cleanNumber.slice(1);
      }
  
      // Créer les formats standards
      const phoneFormats = [
        `0${cleanNumber}`,      // Format national
        `+33${cleanNumber}`,    // Format international
        `33${cleanNumber}`      // Format alternatif
      ];
  
      const usersRef = collection(db, 'users');
      
      // Vérifier tous les formats
      for (let format of phoneFormats) {
        const q = query(usersRef, where('phoneNumber', '==', format));
        const snapshot = await getDocs(q);
        
        // Ignorer le numéro de l'utilisateur actuel
        const exists = snapshot.docs.some(doc => doc.id !== currentUserUid);
        
        if (exists) {
          return { exists: true, success: true };
        }
      }
  
      return { exists: false, success: true };
    } catch (error) {
      console.error('Error checking phone:', error);
      return { 
        success: false,
        error: 'Erreur lors de la vérification du numéro'
      };
    }
  },

  sendPhoneVerification: async (phoneNumber, recaptchaVerifier) => {
    try {
      const phoneCheck = await authService.checkPhoneExists(phoneNumber);
      
      if (phoneCheck.success && !phoneCheck.exists) {
        return { 
          success: false, 
          error: 'Ce numéro n\'est pas associé à un compte',
          needsRegistration: true
        };
      }

      if (!recaptchaVerifier) {
        return { 
          success: false, 
          error: 'Configuration de sécurité manquante'
        };
      }

      const provider = new PhoneAuthProvider(auth);
      const verificationId = await provider.verifyPhoneNumber(
        phoneNumber,
        recaptchaVerifier
      );
      
      console.log("Phone verification sent successfully");
      return { success: true, verificationId };
    } catch (error) {
      console.error("Phone verification error:", error);
      return { 
        success: false, 
        error: getErrorMessage(error),
        errorCode: error.code,
        needsRecaptchaReset: error.code === 'auth/invalid-recaptcha-token'
      };
    }
  },

  verifyPhoneCode: async (verificationId, code) => {
    try {
      const credential = PhoneAuthProvider.credential(verificationId, code);
      const userCredential = await signInWithCredential(auth, credential);
      console.log("Phone verification successful");
      return { success: true, user: userCredential.user };
    } catch (error) {
      console.error("Phone verification error:", error);
      return { 
        success: false, 
        error: getErrorMessage(error),
        errorCode: error.code 
      };
    }
  },

  // Gestion des données utilisateur
  getUserData: async (uid) => {
    try {
      console.log("Getting user data for UID:", uid);
      const userDoc = await getDoc(doc(db, "users", uid));
      console.log("User doc exists:", userDoc.exists());
      if (userDoc.exists()) {
        const data = userDoc.data();
        console.log("User data retrieved:", data);
        return { success: true, data: data };
      } else {
        console.log("No user document found for UID:", uid);
        return { success: false, error: 'Utilisateur non trouvé' };
      }
    } catch (error) {
      console.error("Error getting user data:", error);
      return { 
        success: false, 
        error: 'Erreur lors de la récupération des données' 
      };
    }
  },

  getUserDataByPhone: async (phoneNumber) => {
    try {
      console.log("Getting user data for phone:", phoneNumber);
      
      // Nettoyer le numéro et créer tous les formats possibles
      let cleanNumber = phoneNumber.replace(/[^\d]/g, '');
      
      // Si le numéro commence par 33
      if (cleanNumber.startsWith('33')) {
        cleanNumber = cleanNumber.slice(2);
      }
      // Si le numéro commence par 0
      else if (cleanNumber.startsWith('0')) {
        cleanNumber = cleanNumber.slice(1);
      }
  
      // Créer les formats standards
      const phoneFormats = [
        `0${cleanNumber}`,      // Format national: 0612345678
        `+33${cleanNumber}`,    // Format international: +33612345678
        `33${cleanNumber}`      // Format alternatif: 33612345678
      ];
  
      const usersRef = collection(db, 'users');
      
      // Vérifier tous les formats
      for (let format of phoneFormats) {
        const q = query(usersRef, where('phoneNumber', '==', format));
        const snapshot = await getDocs(q);
        
        if (!snapshot.empty) {
          const userData = snapshot.docs[0].data();
          console.log("User data found by phone format:", format);
          return { success: true, data: userData };
        }
      }
  
      console.log("No user found with any phone format");
      return { success: false, error: 'Utilisateur non trouvé' };
    } catch (error) {
      console.error("Error getting user data by phone:", error);
      return { success: false, error: 'Erreur lors de la récupération des données' };
    }
  },

  // Mise à jour du profil
  updateUserProfile: async (uid, updateData, currentPassword = null) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        return { success: false, error: 'Utilisateur non connecté' };
      }
  
      // Mise à jour Firestore d'abord
      const updateObject = {};
      if (updateData.firstName) updateObject.firstName = updateData.firstName;
      if (updateData.lastName) updateObject.lastName = updateData.lastName;
      if (updateData.phoneNumber) updateObject.phoneNumber = updateData.phoneNumber;
      if (updateData.email) updateObject.email = updateData.email;
  
      // Si il y a des données à mettre à jour dans Firestore
      if (Object.keys(updateObject).length > 0) {
        await updateDoc(doc(db, "users", uid), updateObject);
      }
  
      // Mise à jour du profil Firebase Auth si nom/prénom modifiés
      if (updateData.firstName || updateData.lastName) {
        const currentData = await authService.getUserData(uid);
        const newDisplayName = `${updateData.firstName || currentData.data.firstName} ${updateData.lastName || currentData.data.lastName}`;
        await updateProfile(user, { displayName: newDisplayName });
      }
  
      // Si changement de mot de passe
      if (updateData.newPassword) {
        if (!currentPassword) {
          return { 
            success: false, 
            error: 'Mot de passe actuel requis pour changer le mot de passe' 
          };
        }
  
        try {
          const credential = EmailAuthProvider.credential(user.email, currentPassword);
          await reauthenticateWithCredential(user, credential);
          await updatePassword(user, updateData.newPassword);
        } catch (error) {
          return { 
            success: false, 
            error: getErrorMessage(error),
            errorCode: error.code 
          };
        }
      }
  
      return { success: true };
    } catch (error) {
      console.error("Update profile error:", error);
      return { 
        success: false, 
        error: getErrorMessage(error),
        errorCode: error.code 
      };
    }
  },

  // Réinitialisation du mot de passe
  resetPassword: async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return { success: true };
    } catch (error) {
      console.error("Password reset error:", error);
      return { 
        success: false, 
        error: getErrorMessage(error),
        errorCode: error.code 
      };
    }
  },

  // Déconnexion
  signOut: async () => {
    try {
      await signOut(auth);
      return { success: true };
    } catch (error) {
      console.error("Sign out error:", error);
      return { 
        success: false, 
        error: getErrorMessage(error),
        errorCode: error.code 
      };
    }
  },

  getCurrentUser: () => {
    return auth.currentUser;
  }
};

async function requestNotificationPermission() {
  try {
    const token = await getToken(messaging, {
      vapidKey: "BDvpb3CsEERz5Fm2nJwXsoqxrwBQFKJT__05Sjq7gvHR7EyEIF_HUYFjuj5Q3EbZx5xD55qLTho1W224kEVFhI4"
    });
    await updateFCMToken(token);
    return token;
  } catch (err) {
    console.error('Unable to get token ', err);
  }
}

async function updateFCMToken(token) {
  const user = auth.currentUser;
  if (user) {
    await updateDoc(doc(db, "users", user.uid), {
      fcmToken: token
    });
  }
}

export { auth, db, authService, requestNotificationPermission, updateFCMToken, messaging, setupMessaging };